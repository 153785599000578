import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';

const MeineDaten = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(''); // Zustand für das aktuelle Passwort
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updateError, setUpdateError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Zustand für die Checkboxen
  const [notifications, setNotifications] = useState(false);
  const [saveView, setSaveView] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        setUserData(response.data);
        setNotifications(response.data.notifications || false);
        setSaveView(response.data.save_view || false);
      } catch (error) {
        setError('Fehler beim Laden der Benutzerdaten');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setUpdateError('Passwörter stimmen nicht überein');
      // Setze Fehlernachricht zurück nach 5 Sekunden
      setTimeout(() => setUpdateError(null), 5000);
      return;
    }

    try {
      const userId = userData.id; // Benutzer-ID
      await axiosInstance.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        currentPassword, // Aktuelles Passwort
        password, // Neues Passwort
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      setSuccessMessage('Passwort erfolgreich geändert');
      setCurrentPassword('');
      setPassword('');
      setConfirmPassword('');

      // Setze Erfolgsnachricht zurück nach 5 Sekunden
      setTimeout(() => setSuccessMessage(null), 5000);
    } catch (error) {
      setUpdateError('Fehler beim Ändern des Passworts');
      console.error(error);
      // Setze Fehlernachricht zurück nach 5 Sekunden
      setTimeout(() => setUpdateError(null), 5000);
    }
  };

  const handleToggleChange = async (field, value) => {
    try {
      const userId = userData.id; // Benutzer-ID
      await axiosInstance.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        [field]: value,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      setUserData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      setSuccessMessage('Einstellungen erfolgreich aktualisiert');

      // Setze Erfolgsnachricht zurück nach 5 Sekunden
      setTimeout(() => setSuccessMessage(null), 5000);
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Einstellungen:', error);
      setError('Fehler beim Aktualisieren der Einstellungen');
      // Setze Fehlernachricht zurück nach 5 Sekunden
      setTimeout(() => setError(null), 5000);
    }
  };

  if (loading) {
    return <div className="loading loading-spinner loading-lg"></div>;
  }

  if (error) {
    return <div className="alert alert-error">{error}</div>;
  }

  if (!userData) {
    return <div>Keine Benutzerdaten verfügbar</div>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Benutzerdaten</h2>
      {updateError && <div className="alert alert-error mb-4">{updateError}</div>}
      {successMessage && <div className="alert alert-success mb-4">{successMessage}</div>}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="font-semibold">Name:</label>
          <p>{userData.name}</p>
        </div>
        <div>
          <label className="font-semibold">Vorname:</label>
          <p>{userData.vorname}</p>
        </div>
        <div>
          <label className="font-semibold">Benutzername:</label>
          <p>{userData.username}</p>
        </div>
        <div>
          <label className="font-semibold">E-Mail:</label>
          <p>{userData.email}</p>
        </div>
        <div>
          <label className="font-semibold">NLZ:</label>
          <input
            type="checkbox"
            className="checkbox"
            checked={userData.NLZ}
            disabled
          />
        </div>
        <div>
          <label className="font-semibold">Aktualisiert am:</label>
          <p>{new Date(userData.updatedAt).toLocaleDateString()}</p>
        </div>

        {/* Toggle für Notifications */}
        <div>
          <label className="font-semibold">Benachrichtigungen:</label>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={notifications}
            onChange={(e) => {
              setNotifications(e.target.checked);
              handleToggleChange('notifications', e.target.checked);
            }}
          />
        </div>

        {/* Toggle für Save View */}
        <div>
          <label className="font-semibold">Gesehene Szenen speichern:</label>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={saveView}
            onChange={(e) => {
              setSaveView(e.target.checked);
              handleToggleChange('save_view', e.target.checked);
            }}
          />
          <div>
            <label className="text-sm">
              Diese Funktion speichert auf dem Server die Daten, welche Szenen durch den User angesehen wurden. 
              Durch das spätere Entfernen werden diese Daten unwiderruflich gelöscht.
            </label>
          </div>
        </div>
      </div>

      <h2 className="text-2xl font-bold mt-6 mb-4">Passwort ändern</h2>
      <form onSubmit={handlePasswordChange}>
        <div className="mb-4">
          <label className="block font-semibold mb-2">Neues Passwort:</label>
          <input
            type="password"
            className="input input-bordered w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-semibold mb-2">Passwort bestätigen:</label>
          <input
            type="password"
            className="input input-bordered w-full"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary w-full">Passwort ändern</button>
      </form>
    </div>
  );
};

export default MeineDaten;
