import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig';

const TotalViewsStat = () => {
  const [totalViews, setTotalViews] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTotalViews = async () => {
      try {
        const response = await axiosInstance.get('/videoszene-views?pagination[page]=1&pagination[pageSize]=1');
        setTotalViews(response.data.meta.pagination.total);
      } catch (error) {
        console.error('Error fetching total views:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalViews();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Ladeanzeige
  }

  return (
    <div className="stat">
      <div className="stat-figure text-accent">
        {/* Play Button Icon */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block h-8 w-8 stroke-current">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v18l15-9L5 3z" /> {/* Play button path */}
        </svg>
      </div>
      <div className="stat-title">Alle Views</div>
      <div className="stat-value text-accent">{totalViews}</div>
      <div className="stat-desc">Anzahl aller Views auf dem Portal</div>
    </div>
  );
};

export default TotalViewsStat;
