import React, { useEffect, useState } from 'react';
import Select from 'react-select'; // Importiere React Select
import { fetchAllClubs } from './api/fetchAllClubs';

const FilterComponent = ({
  activeTab,
  selectedCategory,
  setSelectedCategory,
  minRating,
  setMinRating,
  maxRating,
  setMaxRating,
  selectedTheme,
  setSelectedTheme,
  seasons,
  selectedSeason,
  setSelectedSeason,
  leagues,
  selectedLeague,
  setSelectedLeague,
  clubs,
  selectedClub,
  setSelectedClub,
  tagOptions, // Neuen prop für tagOptions hinzufügen
  bewertungskategorien // Neuen prop für bewertungskategorien hinzufügen
}) => {
  const [clubOptions, setClubOptions] = useState([]);

  useEffect(() => {
    const options = clubs.map((club) => ({
      label: club.attributes.name,
      value: club.attributes.name,
      liga: club.attributes.liga || '', // Sicherstellen, dass die Liga vorhanden ist
    }));
    setClubOptions(options);
  }, [clubs]);

  useEffect(() => {
    const loadClubs = async () => {
      const clubs = await fetchAllClubs();
      setClubOptions(clubs); // Speichere die Vereine im State, um sie im Select anzuzeigen
    };
  
    loadClubs(); // Vereine beim Mounten der Komponente laden
  }, []);

  const handleClubChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedClub(selectedOption.value);
    } else {
      setSelectedClub('');
    }
  };

  return (
    <>
      {activeTab === 'Thema' && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Filter nach Thema</h3>
          <Select
            options={tagOptions} // Verwende die übergebenen tagOptions
            isMulti
            value={tagOptions.filter(option => selectedTheme.includes(option.value))} // Zeige die ausgewählten Themen an
            onChange={(selectedOptions) => {
              const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
              setSelectedTheme(values); // Setze die ausgewählten Themen
            }}
            placeholder="Themen auswählen"
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      )}

      {activeTab === 'Bewertung' && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Bewertungskategorien</h3>
          <div className="mb-4">
            <select
              className="select select-bordered w-full"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Alle Kategorien</option>
              {/* Dynamisch die Kategorien aus der kategorien.js einfügen */}
              {Object.keys(bewertungskategorien).map((role) => (
                <optgroup key={role} label={role.toUpperCase()}>
                  {bewertungskategorien[role].map((category, idx) => (
                    <option key={idx} value={category}>
                      {category}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>

          {/* Min-Max Bewertung */}
          <div className="mt-4 flex space-x-4">
            <div>
              <label className="block text-sm font-medium">Minimale Bewertung</label>
              <input
                type="number"
                value={minRating}
                onChange={(e) => setMinRating(parseInt(e.target.value))}
                className="input input-bordered w-full"
                min="1"
                max="6"
              />
            </div>

            <div>
              <label className="block text-sm font-medium">Maximale Bewertung</label>
              <input
                type="number"
                value={maxRating}
                onChange={(e) => setMaxRating(parseInt(e.target.value))}
                className="input input-bordered w-full"
                min="1"
                max="6"
              />
            </div>
          </div>
        </div>
      )}

      {/* Weitere Filter für Liga und Verein */}
      {activeTab === 'Verein/Liga' && (
        <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Saison-Filter */}
          <div>
            <label className="block text-sm font-medium mb-2">Saison</label>
            <select
              className="select select-bordered w-full"
              value={selectedSeason}
              onChange={(e) => {
                setSelectedSeason(e.target.value);
                setSelectedLeague(''); // Zurücksetzen der Liga-Auswahl
                setSelectedClub(''); // Zurücksetzen der Vereins-Auswahl
              }} // Saison setzen
            >
              <option value="">Alle Saisons</option>
              {seasons.map((season) => (
                <option key={season.id} value={season.attributes.titel}>
                  {season.attributes.titel}
                </option>
              ))}
            </select>
          </div>

          {/* Liga-Filter */}
          <div>
            <label className="block text-sm font-medium mb-2">Liga</label>
            <select
              className="select select-bordered w-full"
              value={selectedLeague}
              onChange={(e) => {
                setSelectedLeague(e.target.value);
                setSelectedClub(''); // Zurücksetzen der Vereins-Auswahl
              }} // Liga setzen
            >
              <option value="">Alle Ligen</option>
              {leagues
                .filter(league => league.attributes.Saison === selectedSeason || !selectedSeason) // Filter für Ligen basierend auf der ausgewählten Saison
                .map((league) => (
                  <option key={league.id} value={league.attributes.Name}>
                    {league.attributes.Name}
                  </option>
                ))}
            </select>
          </div>

          {/* Verein-Filter mit React Select */}
          <div className="relative">
    <label className="block text-sm font-medium mb-2">Verein</label>
    <Select
      options={clubOptions.filter(club => club.liga === selectedLeague || !selectedLeague)} // Filter nach Liga
      value={clubOptions.find(option => option.value === selectedClub)}  // Zeige den ausgewählten Verein an
      onChange={handleClubChange}  // Reagiere auf Auswahländerungen
      isClearable  // Ermöglicht das Löschen der Auswahl
      placeholder="Verein auswählen"
      className="react-select-container"
      classNamePrefix="react-select"
    />
  </div>
        </div>
      )}
    </>
  );
};

export default FilterComponent;
