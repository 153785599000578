import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import { setView } from '../redux/viewSlice';

const HeroLoggedIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialisiere den useNavigate Hook

  const handleClick = (view, path) => {
    dispatch(setView(view));
    navigate(path); // Navigiere zur entsprechenden Route
  };

  return (
    <div className="hero bg-base-200 min-h-screen w-11/12 justify-center">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Willkommen im Videoportal 👋</h1>
          <p className="py-6">
            Entdecken Sie spannende Fußball-Szenen und analysieren Sie die entscheidenden Momente in
            unseren exklusiven Videoaufnahmen.
          </p>
          <div className="flex justify-center gap-4">
            <button
              className="btn btn-primary"
              onClick={() => handleClick('Aktuelle Szenen', '/aktuelle-szenen')}
            >
              Aktuelle Szenen
            </button>
            <button
              className="btn btn-outline btn-accent"
              onClick={() => handleClick('Alle Szenen', '/alle-szenen')}
            >
              Alle Szenen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroLoggedIn;


