import React from 'react';

const VideoUpload = ({ videoUrl, handleVideoUpload, loading, scene }) => {
  const handleUploadWithLogging = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('Datei wird hochgeladen:', file.name, file.size, file.type);
    } else {
      console.log('Keine Datei ausgewählt.');
    }
    handleVideoUpload(e, scene);  // Übergibt 'scene' an handleVideoUpload
  };

  return (
    <>
      {videoUrl ? (
        <div className="mb-6">
          <video controls className="w-full rounded-lg shadow-lg" style={{ aspectRatio: '16/9' }}>
            <source src={videoUrl} type="video/mp4" />
            Dein Browser unterstützt kein Video-Tag.
          </video>
        </div>
      ) : (
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Video hochladen</label>
          <input type="file" className="file-input w-full" accept="video/*" onChange={handleUploadWithLogging} disabled={loading} />
        </div>
      )}
    </>
  );
};

export default VideoUpload;
