import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';

const Vereinsverwaltung = () => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [vereine, setVereine] = useState([]);

  // Funktion zum Laden der Vereine
  const loadVereine = async () => {
    try {
      const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/vereins`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      setVereine(res.data.data);
    } catch (err) {
      console.error('Fehler beim Laden der Vereine:', err);
    }
  };

  useEffect(() => {
    loadVereine(); // Lade die Vereine beim ersten Rendern der Komponente
  }, []);

  // Funktion zum Anlegen eines neuen Vereins
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const createRes = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/vereins`,
        {
          data: {
            name,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      setSuccess('Verein erfolgreich angelegt!');
      console.log('Verein angelegt:', createRes.data);

      // Verein zur Liste hinzufügen
      setVereine([...vereine, createRes.data.data]);

      // Formular zurücksetzen
      setName('');
    } catch (err) {
      if (err.response) {
        console.error('Serverantwort:', err.response.data);
        setError(`Fehler beim Anlegen des Vereins: ${err.response.data.error.message}`);
      } else {
        console.error('Fehler beim Anlegen des Vereins:', err.message);
        setError('Fehler beim Anlegen des Vereins');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-base-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Verein anlegen</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Vereinsname</label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="flex justify-between">
          <button type="submit" className="btn btn-success" disabled={loading}>
            {loading ? 'Wird erstellt...' : 'Verein anlegen'}
          </button>
        </div>
      </form>

      <h2 className="text-2xl font-bold mt-8 mb-4">Alle Vereine</h2>
      <ul className="menu bg-base-100 rounded-box p-4 shadow-md">
        {vereine.map((verein) => (
          <li key={verein.id} className="menu-item">
            <a>{verein.attributes.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Vereinsverwaltung;
