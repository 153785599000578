import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig';

const UserCountStat = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axiosInstance.get('/users/count');
        setTotalUsers(response.data);
      } catch (error) {
        console.error('Error fetching user count:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCount();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Ladeanzeige
  }

  return (
    <div className="stat">
      <div className="stat-figure text-secondary">
        {/* Schöneres Benutzer Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          className="inline-block h-8 w-8"
        >
          <path d="M12 12c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0 2c-3.3 0-10 1.7-10 5v2h20v-2c0-3.3-6.7-5-10-5z" />
        </svg>
      </div>
      <div className="stat-title">Alle User</div>
      <div className="stat-value text-secondary">{totalUsers}</div>
      <div className="stat-desc">Anzahl der Benutzer auf dem Portal</div>
    </div>
  );
};

export default UserCountStat;
