import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig'
import EditRelease from './EditRelease'; // Importiere die EditRelease-Komponente

const ReleaseManager = () => {
  const [activeTab, setActiveTab] = useState('Alle Releases');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [releases, setReleases] = useState([]);
  
  // States für die Erstellung eines neuen Releases
  const [name, setName] = useState(''); // Initialisiere den State für den Namen
  const [beschreibung, setBeschreibung] = useState(''); // Initialisiere den State für die Beschreibung

  useEffect(() => {
    const fetchReleases = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/szenenreleases?populate=*`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        setReleases(response.data.data);
        console.log('Abgerufene Releases:', response.data.data);
      } catch (err) {
        console.error('Fehler beim Abrufen der Releases:', err);
        setError('Fehler beim Abrufen der Releases');
      }
    };

    fetchReleases();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const releaseData = {
      name,
      beschreibung,
      Status: 'Geplant ',
    };

    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/szenenreleases`, {
        data: releaseData,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      setReleases(prevReleases => [...prevReleases, response.data.data]);
      setIsModalOpen(false);
      setSuccess('Release erfolgreich erstellt!');
      setName(''); // Zurücksetzen nach dem Speichern
      setBeschreibung(''); // Zurücksetzen nach dem Speichern
    } catch (err) {
      setError(err.response.data.error.message || 'Unbekannter Fehler');
    } finally {
      setLoading(false);
    }
  };

  const getFilteredReleases = () => {
    if (activeTab === 'Geplant') {
      return releases.filter(release => release.attributes.Status.trim() === 'Geplant');
    } else if (activeTab === 'Veröffentlicht') {
      return releases.filter(release => release.attributes.Status.trim() === 'Veröffentlicht');
    }
    return releases;
  };

  const handleEdit = (release) => {
    setSelectedRelease(release);
    setEditMode(true);
  };

  const handleSaveEdit = (updatedRelease) => {
    setReleases(prevReleases =>
      prevReleases.map(release =>
        release.id === updatedRelease.id ? updatedRelease : release
      )
    );
    setEditMode(false);
    setSelectedRelease(null); // Setze die ausgewählte Release zurück
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setSelectedRelease(null); // Setze die ausgewählte Release zurück
  };

  return (
    <div className="w-full">
      {editMode && selectedRelease ? (
        <EditRelease
          releaseId={selectedRelease.id} // Übergib die ID des Releases
          onSave={handleSaveEdit}
          onCancel={handleCancelEdit}
        />
      ) : (
        <>
          <h1 className="text-3xl font-bold mb-4 text-center">Release Manager</h1>
          <p className="mb-6 text-center">Hier kannst du die Videoszenen verwalten und freigeben.</p>

          <div className="flex justify-center mb-4">
            <button className="btn btn-primary" onClick={() => setIsModalOpen(true)}>
              Neuen Release anlegen
            </button>
          </div>

          {isModalOpen && (
            <div className="modal modal-open">
              <div className="modal-box">
                <h2 className="font-bold text-lg">Neuen Release anlegen</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Name</label>
                    <input
                      type="text"
                      className="input input-bordered w-full"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Beschreibung</label>
                    <textarea
                      className="textarea textarea-bordered w-full"
                      value={beschreibung}
                      onChange={(e) => setBeschreibung(e.target.value)}
                    />
                  </div>
                  <div className="flex justify-end">
                    <button type="button" className="btn btn-secondary mr-2" onClick={() => setIsModalOpen(false)}>
                      Abbrechen
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      {loading ? 'Wird gespeichert...' : 'Speichern'}
                    </button>
                  </div>
                </form>
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}
              </div>
            </div>
          )}

          <div className="flex justify-center mb-4">
            <div className="tabs tabs-boxed">
              <a
                role="tab"
                className={`tab ${activeTab === 'Alle Releases' ? 'tab-active' : ''}`}
                onClick={() => setActiveTab('Alle Releases')}
              >
                Alle Releases
              </a>
              <a
                role="tab"
                className={`tab ${activeTab === 'Geplant' ? 'tab-active' : ''}`}
                onClick={() => setActiveTab('Geplant')}
              >
                Geplant
              </a>
              <a
                role="tab"
                className={`tab ${activeTab === 'Veröffentlicht' ? 'tab-active' : ''}`}
                onClick={() => setActiveTab('Veröffentlicht')}
              >
                Veröffentlicht
              </a>
            </div>
          </div>

          <div className="mt-4 p-4 border rounded">
            <h3 className="text-lg font-bold">
              {activeTab === 'Alle Releases'
                ? 'Alle Releases'
                : activeTab === 'Geplant'
                ? 'Geplante Releases'
                : 'Veröffentlichte Releases'}
            </h3>
            {getFilteredReleases().length > 0 ? (
              getFilteredReleases().map(release => (
                <div key={release.id} className="mb-4 p-4 border-b">
                  <p>Name: {release.attributes.name}</p>
                  <p>Status: {release.attributes.Status}</p>
                  <button
                    className="btn btn-sm btn-info mt-2"
                    onClick={() => handleEdit(release)}
                  >
                    Bearbeiten
                  </button>
                </div>
              ))
            ) : (
              <p>Keine Releases verfügbar.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReleaseManager;
