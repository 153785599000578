import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from './axiosConfig';
import { setUser } from '../redux/userSlice'; // Action für Redux

const UnconfirmedUsersFromDistrict = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user); // Benutzer aus Redux
  const [unconfirmedUsers, setUnconfirmedUsers] = useState([]); // Für unbestätigte Benutzer
  const [error, setError] = useState(null); // Fehlerhandling

  // Benutzerinformationen laden, falls nicht im Redux-Store
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        try {
          const response = await axiosInstance.get('/users/me?populate=*');
          const userData = response.data;
          dispatch(setUser(userData)); // Benutzerdaten in den Redux-Store speichern
        } catch (error) {
          console.error('Fehler beim Abrufen der Benutzerinformationen:', error);
          setError('Fehler beim Laden der Benutzerinformationen');
        }
      }
    };

    fetchUserData();
  }, [dispatch, user]);

  // Funktion zum Abrufen der unbestätigten Benutzer
  useEffect(() => {
    const fetchUnconfirmedUsers = async () => {
      if (user && user.bezirk) {
        try {
          const response = await axiosInstance.get('/users?populate=*'); // API-Aufruf
          const usersData = response.data;

          // Filter: Benutzer aus dem gleichen Bezirk, die nicht bestätigt sind
          const filteredUsers = usersData.filter(
            (userItem) =>
              userItem.bezirk?.name === user.bezirk.name && // Gleicher Bezirk
              userItem.confirmed === false // Benutzer nicht bestätigt
          );

          setUnconfirmedUsers(filteredUsers);
        } catch (error) {
          console.error('Fehler beim Abrufen der Benutzerliste:', error);
          setError('Fehler beim Laden der Benutzerdaten');
        }
      }
    };

    if (user) {
      fetchUnconfirmedUsers(); // Nur ausführen, wenn der aktuelle Benutzer vorhanden ist
    }
  }, [user]);

  if (error) {
    return <div>{error}</div>; // Fehleranzeige
  }

  if (!user) {
    return <div>Lade Benutzerinformationen...</div>; // Ladeanzeige, wenn der Benutzer noch nicht verfügbar ist
  }

  if (unconfirmedUsers.length === 0) {
    return <div>Keine unbestätigten Benutzer im gleichen Bezirk gefunden.</div>; // Keine unbestätigten Benutzer gefunden
  }

  return (
    <div className="card bg-base-100 shadow-md p-4">
      <h2 className="card-title">Unbestätigte Benutzer aus deinem Bezirk</h2>
      
      {/* Container mit fester Höhe und Scrollfunktion */}
      <div className="overflow-y-auto" style={{ maxHeight: '300px' }}>
        <ul>
          {unconfirmedUsers.map((userItem) => (
            <li key={userItem.id} className="my-2">
              <div className="flex justify-between">
                <span>{userItem.vorname} {userItem.name}</span>
                <span>Nicht bestätigt</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UnconfirmedUsersFromDistrict;
