import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig';
import SzeneKarte from './SzeneKarte'; // Importiere die SzeneKarte-Komponente

const AktuelleSzenen = () => {
  const [releases, setReleases] = useState([]);

  useEffect(() => {
    const fetchReleases = async () => {
      try {
        const response = await axiosInstance.get(
         `${process.env.REACT_APP_API_URL}/szenenreleases?filters[Status][$eq]=Veröffentlicht&populate=videoszenes.thumbnail,videoszenes.thema&sort=released_at:desc`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
        );
        console.log(response.data);
        const sortedReleases = response.data.data.sort(
          (a, b) => new Date(b.attributes.released_at) - new Date(a.attributes.released_at)
        );

        // Client-seitiges Filtern von Szenen mit arbeitsstatus "published"
        const filteredReleases = sortedReleases.map(release => {
          const filteredScenes = release.attributes.videoszenes?.data.filter(
            scene => scene.attributes.arbeitsstatus === 'published'
          );
          return {
            ...release,
            attributes: {
              ...release.attributes,
              videoszenes: {
                data: filteredScenes,
              },
            },
          };
        });

        setReleases(filteredReleases);
      } catch (error) {
        console.error('Fehler beim Abrufen der Releases:', error);
      }
    };

    fetchReleases();
  }, []);

  return (
    <div className="w-11/12 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Aktuelle Szenen</h2>

      {releases.length > 0 ? (
        releases.map((release) => (
          <div key={release.id} className="mb-8">
            <h3 className="text-xl font-bold mb-4">{release.attributes.name}</h3>
            <p className="text-sm mb-4">
              Veröffentlicht am: {new Date(release.attributes.released_at).toLocaleDateString()}
            </p>
            <p className="text-base mb-4">
              {release.attributes.beschreibung}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {release.attributes.videoszenes?.data.length > 0 ? (
                release.attributes.videoszenes.data.map((scene) => (
                  // We are passing releaseId to SzeneKarte here
                  <SzeneKarte 
  key={scene.id} 
  scene={scene} 
  from="/aktuelle-szenen" 
  releaseId={release.id} 
/>
                ))
              ) : (
                <p>Keine Szenen verfügbar.</p>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>Keine Releases verfügbar.</p>
      )}
    </div>
  );
};

export default AktuelleSzenen;
