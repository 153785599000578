import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-primary text-base-100 items-center p-4 mt-4">
      <aside className="flex justify-between items-center w-full"> {/* Flexbox für Layout */}
        <img 
          src="/Logo-weiss.png" // Pfad zu deinem Logo
          alt="Logo"
          className="logo-image mr-2" // Logo-Klasse mit Abstand
          style={{ maxHeight: '50px' }} // Maximale Höhe des Logos auf 50px setzen
        />
        <p className="text-base-100 text-right">© {new Date().getFullYear()} - v1.0.0</p> {/* Schriftfarbe auf base-100 setzen und rechtsbündig */}
      </aside>
    </footer>
  );
};

export default Footer;
