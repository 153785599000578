import React, { useState, useEffect, cloneElement } from 'react';
import axiosInstance from './axiosConfig';
import VideoszeneModal from './VideoszeneModal';

const SceneGrid = ({ scenes, selectedScenes, onSceneToggle, gameData }) => {
  const [signedUrls, setSignedUrls] = useState({});
  const [selectedSceneId, setSelectedSceneId] = useState(null); // Für die ausgewählte Szene im Modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Status des Modals

  const [currentPage, setCurrentPage] = useState(1); // Für die aktuelle Seite
  const scenesPerPage = 16; // 4 Zeilen à 4 Szenen = 16 Szenen pro Seite
  const totalPages = Math.ceil(scenes.length / scenesPerPage); // Berechne die Gesamtzahl der Seiten

  useEffect(() => {
    const fetchSignedUrls = async () => {
      const urls = {};
      for (const scene of scenes) {
        if (scene.attributes.thumbnail?.data?.attributes?.hash) {
          const thumbnailKey = scene.attributes.thumbnail.data.attributes.hash + '.jpg';
          try {
            const res = await axiosInstance.get(
              `${process.env.REACT_APP_API_URL}/get-signed-thumbnail-url/${thumbnailKey}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
              }
            );
            urls[scene.id] = res.data.signedUrl;
          } catch (error) {
            console.error('Fehler beim Abrufen des signierten URL:', error);
          }
        }
      }
      setSignedUrls(urls);
    };

    fetchSignedUrls();
  }, [scenes]);

  // Funktion zum Öffnen des Modals mit einer bestimmten Szene
  const openModal = (sceneId) => {
    setSelectedSceneId(sceneId);
    setIsModalOpen(true);
  };

  // Funktion zum Schließen des Modals
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSceneId(null);
  };

  // Berechne die Szenen für die aktuelle Seite
  const indexOfLastScene = currentPage * scenesPerPage;
  const indexOfFirstScene = indexOfLastScene - scenesPerPage;
  const currentScenes = scenes.slice(indexOfFirstScene, indexOfLastScene); // Szenen für die aktuelle Seite

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {currentScenes.length > 0 ? (
          currentScenes.map((scene) => {
            const isSelected = selectedScenes.includes(scene.id);

            return (
              <div
                key={scene.id}
                className="card shadow-md p-4 border flex flex-col justify-between relative"
              >
                {/* Themen als Tags über dem Thumbnail anzeigen */}
                {scene.attributes.thema?.length > 0 && (
                  <div className="absolute top-2 left-2 flex flex-wrap gap-2 z-10">
                    {scene.attributes.thema.map((thema) => (
                      <span key={thema.id} className="badge badge-info">
                        {thema.name}
                      </span>
                    ))}
                  </div>
                )}

                {/* Thumbnail-Bereich */}
                <div className="w-full relative pb-[56.25%] mb-4">
                  <img
                    src={signedUrls[scene.id] || scene.attributes.thumbnail?.data?.attributes?.url} // Signierter URL oder Standard-URL
                    alt={scene.attributes.titel}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                  />
                </div>

                {/* Status und Checkbox zum Hinzufügen zum Release */}
                <div className="flex justify-between items-center">
                  <div className="badge badge-ghost">{scene.attributes.arbeitsstatus}</div>
                  <div>
                    <label className="cursor-pointer">
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => onSceneToggle(scene.id)}
                        className="checkbox"
                      />
                    </label>
                  </div>
                </div>

                {/* Anzeige der Spieldaten */}
                {scene.attributes.spiel?.data && (
                  <div className="mt-2">
                    <p className="text-sm">
                      {gameData[scene.attributes.spiel.data.id]?.heimverein?.data?.attributes?.name || 'N/A'} -{' '}
                      {gameData[scene.attributes.spiel.data.id]?.gastverein?.data?.attributes?.name || 'N/A'}
                    </p>
                    <p className="text-sm antialiased">
                      {gameData[scene.attributes.spiel.data.id]?.liga?.data?.attributes?.Name || 'N/A'}
                    </p>
                  </div>
                )}

                <h4 className="font-bold text-lg mt-2">{scene.attributes.titel}</h4>

                {/* Klickbare Szene, um das Modal zu öffnen */}
                <div className="mt-auto">
                  <button
                    className="btn btn-sm btn-primary w-full mt-4"
                    onClick={() => openModal(scene.id)}
                  >
                    Details anzeigen
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p>Keine Szenen verfügbar.</p>
        )}
      </div>

      {/* Pagination mit DaisyUI Radio Buttons */}
      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <div className="join">
            {Array.from({ length: totalPages }, (_, index) => (
              <input
                key={index + 1}
                className={`join-item btn btn-square ${currentPage === index + 1 ? 'btn-active' : ''}`}
                type="radio"
                name="pagination"
                aria-label={`${index + 1}`}
                checked={currentPage === index + 1}
                onChange={() => setCurrentPage(index + 1)}
              />
            ))}
          </div>
        </div>
      )}

      {/* Modal für die VideoszeneAnsicht */}
      {isModalOpen && selectedSceneId && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-11/12 md:w-2/3 lg:w-1/2 h-[80%] rounded-lg shadow-lg p-6 relative overflow-auto">
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-xl font-bold"
              onClick={closeModal}
            >
              &times;
            </button>

            {/* Hier wird die VideoszeneAnsicht geklont und in das Modal geladen */}
            {cloneElement(<VideoszeneModal />, { id: selectedSceneId })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SceneGrid;
