import React, { useState } from 'react';
import VideoszenenFormular from './VideoszenenFormular'; 
import SpielAuswahl from './SpielAuswahl'; 
import { handleSave } from '../utils/sceneUtils';

const NeueVideoszene = ({ onSceneCreated }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [spielId, setSpielId] = useState(null); // Speichert die Spiel-ID

  // Log hinzufügen, um die Änderung der Spiel-ID zu verfolgen
  const handleSpielIdChange = (id) => {
    console.log("Spiel-ID aus SpielAuswahl:", id);
    setSpielId(id);
  };

  const handleSubmit = async (e, formData) => {
    console.log("Spiel-ID beim Absenden des Formulars:", spielId); // Log Spiel-ID
    if (!spielId) {
      setError("Bitte wählen Sie ein Spiel aus oder legen Sie ein neues Spiel an.");
      return;
    }

    const newScene = {
      id: null,  // Da es eine neue Szene ist, gibt es noch keine ID
      attributes: {
        spiel: { data: { id: spielId } },  // Verwende die ausgewählte Spiel-ID
      }
    };

    await handleSave(
      e,
      newScene,  // Übergabe der leeren Szene ohne ID
      formData.titel,
      formData.beschreibung,
      formData.spielstrafe,
      formData.persoenlicheStrafe,
      formData.konfiRelevant,
      formData.download,
      formData.quelle,
      formData.timecode,
      formData.tags,
      formData.bewertungen,
      spielId,  // Spiel-ID direkt übergeben
      setLoading,
      setError,
      setSuccess,
      onSceneCreated
    );
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Neue Videoszene erstellen</h2>

      {/* Spielauswahl-Komponente */}
      <SpielAuswahl onSpielIdChange={handleSpielIdChange} />

      {/* VideoszenenFormular-Komponente */}
      <VideoszenenFormular
        scene={{ attributes: {} }} // Leere Szene für die neue Videoszene
        onSubmit={handleSubmit}
        loading={loading}
        setLoading={setLoading}
        error={error}
        success={success}
        setError={setError}
        setSuccess={setSuccess}
        isNewScene={true} // Kennzeichnung, dass es sich um eine neue Szene handelt
      />

      {/* Fehler- und Erfolgsmeldungen */}
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
    </div>
  );
};

export default NeueVideoszene;
