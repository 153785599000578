import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axiosInstance from './axiosConfig';
import emailjs from 'emailjs-com'; // Schon importiert

const NewUserModal = ({ isOpen, onClose, fetchUsers, districts }) => {
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    name: '',
    vorname: '',
    sr_rolle: [],
    bezirk: null,
    confirmed: false,
    blocked: false,
    NLZ: false,
    ligatyp: null, // Hier speichern wir den ausgewählten Ligatyp
  });

  const [ligatypen, setLigatypen] = useState([]); // Zustand für die Ligatypen
  const [error, setError] = useState(null); // Fehlerhandling
  const [successMessage, setSuccessMessage] = useState('');

  // Optionen für die Rollen
  const roleOptions = [
    //{ value: 'VSA', label: 'VSA' },
    { value: 'BSA', label: 'BSA' },
    { value: 'BEO', label: 'BEO' },
    { value: 'SR', label: 'SR' },
    { value: 'LW', label: 'LW' },
    { value: 'GSO', label: 'GSO' },
    { value: 'GSA', label: 'GSA' },
    //{ value: 'KT', label: 'KT' }
  ];

  // API-Aufruf, um Ligatypen aus der Datenbank zu laden
  useEffect(() => {
    const fetchLigatypen = async () => {
      try {
        const response = await axiosInstance.get('/ligatyps'); // Endpunkt für Ligatypen
        console.log('Ligatypen API Response:', response.data); // Debugging: Ausgabe der API-Antwort
  
        // Überprüfe, ob die Daten in einem Array vorliegen oder weiter verschachtelt sind
        const ligatypData = Array.isArray(response.data) ? response.data : response.data.data; // Passe den Zugriff auf die tatsächliche Datenstruktur an
  
        const options = ligatypData.map(ligatyp => ({
          value: ligatyp.id,
          label: ligatyp.attributes.Name,
        }));
        
        setLigatypen(options);
      } catch (error) {
        console.error('Fehler beim Abrufen der Ligatypen:', error);
        setError('Fehler beim Laden der Ligatypen');
      }
    };
  
    fetchLigatypen(); // Ligatypen abrufen
  }, []);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleRoleChange = (selectedOptions) => {
    const roles = selectedOptions ? selectedOptions.map(option => ({ rolle: option.value })) : [];
    setNewUser((prevUser) => ({
      ...prevUser,
      sr_rolle: roles,
    }));
  };

  const handleDistrictChange = (selectedOption) => {
    setNewUser((prevUser) => ({
      ...prevUser,
      bezirk: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleLigatypChange = (selectedOption) => {
    setNewUser((prevUser) => ({
      ...prevUser,
      ligatyp: selectedOption ? selectedOption.value : null,
    }));
  };

  const generatePassword = () => {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const sendConfirmationEmail = (email, username, password, vorname) => {
    const templateParams = {
      username: username,
      password: password,
      vorname: vorname, // Vorname des Benutzers hinzufügen
      homepage_url: process.env.REACT_APP_HOMEPAGE_URL, // Homepage URL aus Umgebungsvariablen
      user_email: email // Empfänger E-Mail
    };
  

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, // Deine Service-ID
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID_REG, // Deine Template-ID
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID // Deine User-ID
    )
    .then((response) => {
      console.log('Email successfully sent!', response.status, response.text);
      setSuccessMessage(`${newUser.vorname} wurde erfolgreich über seinen Zugang informiert.`);
  
    })
    .catch((err) => {
      console.error('Error sending email:', err);
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = generatePassword();
  
    if (!newUser.vorname || !newUser.name) {
      alert("Bitte geben Sie Vorname und Nachname ein.");
      return;
    }
  
    let baseUsername = `${newUser.vorname}_${newUser.name}`.toLowerCase().replace(/\s+/g, '_');
    let uniqueUsername = baseUsername;
  
    try {
      let counter = 1;
      let isUnique = false;
  
      while (!isUnique) {
        const response = await axiosInstance.get(`/users?filters[username][$eq]=${uniqueUsername}`);
        if (response.data.length === 0) {
          isUnique = true;
        } else {
          uniqueUsername = `${baseUsername}_${counter}`;
          counter++;
        }
      }
  
      const userData = {
        username: uniqueUsername,
        email: newUser.email,
        password,
        confirmed: false,
        blocked: false,
        role: '1',
        NLZ: newUser.NLZ,
        name: newUser.name,
        vorname: newUser.vorname,
        bezirk: newUser.bezirk,
        sr_rolle: newUser.sr_rolle,
        ligatyp: newUser.ligatyp,
      };
  
      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/users`, userData);
  
      // Sende eine Bestätigungs-E-Mail
      await sendConfirmationEmail(newUser.email, uniqueUsername, password, newUser.vorname);
      
  
      
      // Benutzerliste neu laden
      fetchUsers();
  
      // Reset the newUser state to clear the form
      setNewUser({
        username: '',
        email: '',
        name: '',
        vorname: '',
        sr_rolle: [],
        bezirk: null,
        confirmed: false,
        blocked: false,
        NLZ: false,
        ligatyp: null,
      });
  
      // Modal schließen erst nach Reset
      onClose();
  
    } catch (error) {
      console.error('Fehler beim Erstellen des Users:', error);
      alert('Fehler beim Erstellen des Benutzers.');
    }
  };
    
  

  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Neuen User anlegen</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label className="label">E-Mail</label>
            <input
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleInputChange}
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">Vorname</label>
            <input
              type="text"
              name="vorname"
              value={newUser.vorname}
              onChange={handleInputChange}
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label">Name</label>
            <input
              type="text"
              name="name"
              value={newUser.name}
              onChange={handleInputChange}
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label">Rollen</label>
            <Select
              isMulti
              options={roleOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleRoleChange}
              value={roleOptions.filter(role => newUser.sr_rolle.map(r => r.rolle).includes(role.value))} // Nur die ausgewählten Rollen anzeigen
            />
          </div>
          <div className="form-control mb-4">
            <label className="label">Bezirk</label>
            <Select
              options={districts}
              className="basic-single-select"
              classNamePrefix="select"
              onChange={handleDistrictChange}
              isClearable
              placeholder="Wählen Sie einen Bezirk"
            />
          </div>
          <div className="form-control mb-4">
            <label className="label">Ligatyp</label>
            <Select
              options={ligatypen}
              className="basic-single-select"
              classNamePrefix="select"
              onChange={handleLigatypChange}
              isClearable
              placeholder="Wählen Sie einen Ligatyp"
            />
          </div>
          <div className="form-control">
            <label className="label">NLZ</label>
            <input
              type="checkbox"
              name="NLZ"
              checked={newUser.NLZ}
              onChange={() => setNewUser((prevUser) => ({ ...prevUser, NLZ: !prevUser.NLZ }))}
              className="checkbox"
            />
          </div>

          <div className="modal-action">
            <button type="submit" className="btn btn-primary">
              Speichern
            </button>
            <button type="button" className="btn" onClick={onClose}>
              Abbrechen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewUserModal;
