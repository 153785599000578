// Tag-Optionen für die Mehrfachauswahl
export const tagOptions = [
    { value: 'Foulspiel', label: 'Foulspiel' },
    { value: 'DOGSO-SPA', label: 'DOGSO-SPA' },
    { value: 'Handspiel', label: 'Handspiel' },
    { value: 'Halten', label: 'Halten' },
    { value: 'Armeinsatz', label: 'Armeinsatz' },
    { value: 'Strafraumsituationen', label: 'Strafraumsituationen' },
    { value: 'Simulation', label: 'Simulation' },
    { value: 'Abseits', label: 'Abseits' },
    { value: 'Teamarbeit', label: 'Teamarbeit' },
    { value: 'Regelsicherheit/-auslegung', label: 'Regelsicherheit/-auslegung' },
    { value: 'Fahnentechnik', label: 'Fahnentechnik' },
    { value: 'Lauf-/Stellungsspiel', label: 'Lauf-/Stellungsspiel' },
    { value: 'Lauf-/Stellungsspiel SRA', label: 'Lauf-/Stellungsspiel SRA' },
    { value: 'Match-Management', label: 'Match-Management' },
    { value: 'Auftreten/Persönlichkeit', label: 'Auftreten/Persönlichkeit' },
  ];
  