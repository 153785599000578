import React, { useState } from 'react';
import Pagination from './Pagination'; // Importiere die Pagination-Komponente

const UserTable = ({ users, handleEditUser }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 20; // Anzahl der Benutzer pro Seite

  // Berechne die aktuelle Benutzerliste basierend auf der Seite
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Berechne die Gesamtzahl der Seiten
  const totalPages = Math.ceil(users.length / usersPerPage);

  // Funktion zum Ändern der Seite
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="overflow-x-auto">
      <table className="table w-full table-fixed">
        <thead>
          <tr>
            <th style={{ width: '15%' }}>Name</th>
            <th style={{ width: '15%' }}>Vorname</th>
            <th className="hidden md:table-cell" style={{ width: '25%' }}>Email</th>
            <th className="hidden sm:table-cell" style={{ width: '10%' }}>Rolle</th>
            <th className="hidden lg:table-cell" style={{ width: '15%' }}>Bezirk</th>
            <th className="hidden xl:table-cell" style={{ width: '15%' }}>Spielklasse</th>
            <th className="hidden md:table-cell" style={{ width: '10%' }}>NLZ</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr
              key={user.id}
              onClick={() => handleEditUser(user)}
              className="cursor-pointer hover:bg-gray-200"
            >
              <td>{user.name}</td>
              <td>{user.vorname}</td>
              <td className="hidden md:table-cell">{user.email}</td>
              <td className="hidden sm:table-cell">
                {user.sr_rolle
                  ? user.sr_rolle
                      .map((role) => role.rolle)
                      .sort()
                      .join(', ')
                  : 'Keine Rollen'}
              </td>
              <td className="hidden lg:table-cell">{user.bezirk ? user.bezirk.name : 'N/A'}</td>
              <td className="hidden xl:table-cell">{user.ligatyp?.Name || 'N/A'}</td>
              <td className="hidden md:table-cell">{user.NLZ ? 'Ja' : 'Nein'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Component */}
      <Pagination 
        totalPages={totalPages} 
        currentPage={currentPage} 
        handlePageChange={handlePageChange} 
      />
    </div>
  );
};

export default UserTable;
