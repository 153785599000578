import React, { useState, useEffect } from 'react';
import VideoszenenFormular from './VideoszenenFormular';
import SpielHinzufuegen from './SpielHinzufuegen'; // Komponente zum Hinzufügen eines Spiels
import { handleSave, updateScene } from '../utils/sceneUtils'; // API-Aufrufe
import { getSpielDataById } from '../utils/spielUtils'; // API zum Abrufen der Spiel-Daten
import { useNavigate, useLocation } from 'react-router-dom';

const VideoszeneBearbeiten = ({ scene, onClose, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [spielId, setSpielId] = useState(scene?.attributes?.spiel?.data?.id || null); // Spiel-ID aus Szene initialisieren
  const [spielData, setSpielData] = useState(null); // Spiel-Daten speichern
  const [arbeitsstatus, setArbeitsstatus] = useState(scene?.attributes?.arbeitsstatus || 'geschnitten'); // Arbeitsstatus speichern
  const navigate = useNavigate();
  const location = useLocation();

  console.log('Initiale Szene-Daten:', scene);
  console.log('Aktuelle Spiel-ID:', spielId);
  console.log('Aktueller Arbeitsstatus:', arbeitsstatus);

  // Lade die Spiel-Daten, wenn sich die spielId ändert
  useEffect(() => {
    if (spielId) {
      console.log('Lade Spiel-Daten für Spiel-ID:', spielId); // Log der Spiel-ID
      setLoading(true);

      getSpielDataById(spielId)
        .then((data) => {
          if (data) {
            console.log('Spiel-Daten vom Backend erfolgreich erhalten:', data); // Log der Spiel-Daten vom Backend
            setSpielData(data);
          } else {
            console.log('Keine Spiel-Daten vom Backend erhalten');
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error('Fehler beim Laden der Spiel-Daten:', err); // Log bei Fehlern
          setError('Fehler beim Laden der Spiel-Daten');
          setLoading(false);
        });
    } else {
      console.warn('Keine gültige Spiel-ID vorhanden. Spiel-Daten werden nicht geladen.');
      setSpielData(null); // Setze spielData auf null, wenn keine Spiel-ID vorhanden ist
    }
  }, [spielId]);

  // Lock auf false setzen, wenn das Fenster geschlossen oder die Seite gewechselt wird
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log(`Entsperre Szene ${scene.id} beim Verlassen der Seite`);
      updateScene(scene.id, { locked: false });
      event.returnValue = ''; // Zeigt die Standard-Browser-Bestätigung an
    };

    // Füge den Event Listener hinzu, der das Fenster-Verlassen überwacht
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup: Entferne den Event Listener beim Unmount der Komponente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [scene.id]);

  // Entsperre die Szene, wenn der Benutzer die Route wechselt
  useEffect(() => {
    const previousLocation = location;
    
    return () => {
      if (location !== previousLocation) {
        console.log(`Navigiere zu einer neuen Seite, entsperre Szene ${scene.id}`);
        updateScene(scene.id, { locked: false });
      }
    };
  }, [location, scene.id]);

  // Entsperren der Szene, wenn der Benutzer das Bearbeiten explizit beendet
  const handleClose = async () => {
    try {
      console.log(`Schließe Szene ${scene.id} und entsperre sie.`);
      await updateScene(scene.id, { locked: false });
    } catch (err) {
      console.error(`Fehler beim Entsperren der Szene ${scene.id}:`, err);
    } finally {
      onClose(); // Schließen der Bearbeitung
    }
  };

  // Funktion zum Veröffentlichen oder Zurückziehen der Szene
  const handleStatusToggle = async () => {
    const newStatus = arbeitsstatus === 'geschnitten' ? 'published' : 'geschnitten';
    console.log('Aktualisiere Arbeitsstatus auf:', newStatus);

    const updatedScene = {
      ...scene,
      attributes: {
        ...scene.attributes,
        arbeitsstatus: newStatus, // Neuer Status
      },
    };

    try {
      setLoading(true);
      console.log('Aktualisierte Szene-Daten vor dem Speichern:', updatedScene);

      // API-Aufruf zum Aktualisieren des Arbeitsstatus
      await updateScene(scene.id, { arbeitsstatus: newStatus });

      console.log('Arbeitsstatus erfolgreich aktualisiert.');
      setSuccess(`Arbeitsstatus erfolgreich auf "${newStatus}" gesetzt.`);
      setArbeitsstatus(newStatus); // Lokalen Status aktualisieren
      setLoading(false);

      // Optional: Führe ein Update der lokalen Szene durch, wenn nötig
      onUpdate && onUpdate(updatedScene);
    } catch (err) {
      console.error('Fehler beim Aktualisieren des Arbeitsstatus:', err);
      setError('Fehler beim Aktualisieren des Arbeitsstatus');
      setLoading(false);
    }
  };

  // Funktion zum Entfernen des Spiels
  const handleRemoveSpiel = async () => {
    setSpielId(null);
    setSpielData(null);

    const updatedScene = {
      ...scene,
      attributes: {
        ...scene.attributes,
        spiel: null,
      },
    };

    try {
      setLoading(true);
      await updateScene(scene.id, { spiel: null });
      setSuccess('Spiel wurde erfolgreich entfernt.');
      setLoading(false);

      onUpdate && onUpdate(updatedScene);
    } catch (err) {
      setError('Fehler beim Entfernen des Spiels');
      setLoading(false);
    }
  };

  // Funktion zum Hinzufügen eines Spiels
  const handleSpielHinzufuegen = async (newSpielId) => {
    setSpielId(newSpielId);

    const updatedScene = {
      ...scene,
      attributes: {
        ...scene.attributes,
        spiel: newSpielId,
      },
    };

    try {
      setLoading(true);
      await updateScene(scene.id, { spiel: newSpielId });
      setSuccess('Spiel wurde erfolgreich hinzugefügt.');
      setLoading(false);

      onUpdate && onUpdate(updatedScene);
    } catch (err) {
      setError('Fehler beim Hinzufügen des Spiels');
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Videoszene bearbeiten</h2>

      {/* Arbeitsstatus Button nur anzeigen, wenn der Status nicht "erstellt" ist */}
      {arbeitsstatus !== 'erstellt' && (
        <button
          className={`btn mr-4 ${arbeitsstatus === 'geschnitten' ? 'btn-success' : 'btn-warning'} mb-4`}
          onClick={handleStatusToggle}
          disabled={loading}
        >
          {loading
            ? 'Aktualisiere Status...'
            : arbeitsstatus === 'geschnitten'
            ? 'Veröffentlichen'
            : 'Zurückziehen'}
        </button>
      )}

      {/* Button zum Entfernen des Spiels */}
      {spielId && (
        <button
          className="btn btn-error mb-4"
          onClick={handleRemoveSpiel}
          disabled={loading}
        >
          {loading ? 'Entferne Spiel...' : 'Spiel entfernen'}
        </button>
      )}

      {/* Spiel hinzufügen, wenn kein Spiel vorhanden ist */}
      {!spielId && <SpielHinzufuegen onSpielHinzufuegen={handleSpielHinzufuegen} />}

      {/* VideoszenenFormular-Komponente */}
      <VideoszenenFormular
        scene={scene}
        spielData={spielData}
        onSubmit={(e, formData) => {
          e.preventDefault();

          const updatedScene = {
            ...scene,
            attributes: {
              ...scene.attributes,
              spiel: spielId ? { data: { id: spielId } } : null,
            },
          };

          handleSave(
            e,
            updatedScene,
            formData.titel,
            formData.beschreibung,
            formData.spielstrafe,
            formData.persoenlicheStrafe,
            formData.konfiRelevant,
            formData.download,
            formData.quelle,
            formData.timecode,
            formData.tags,
            formData.bewertungen,
            setLoading,
            setError,
            setSuccess,
            onUpdate
          );
        }}
        loading={loading}
        setLoading={setLoading}
        error={error}
        success={success}
        setError={setError}
        setSuccess={setSuccess}
        onClose={onClose}
      />

      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
    </div>
  );
};

export default VideoszeneBearbeiten;
