import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import SzeneKarte from './SzeneKarte'; // Importiere die SzeneKarte Komponente

const RelatedScenesSidebar = ({ releaseId }) => {
  const [relatedScenes, setRelatedScenes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRelatedScenes = async () => {
      try {
        if (releaseId) {
          const response = await axiosInstance.get(
            `${process.env.REACT_APP_API_URL}/szenenreleases/${releaseId}?populate=videoszenes,videoszenes.thumbnail,`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              },
            }
          );

          // Sicherstellen, dass die Daten vorhanden sind und das Array gültig ist
          const scenesData = response.data.data.attributes.videoszenes.data;
          if (Array.isArray(scenesData)) {
            setRelatedScenes(scenesData);
          } else {
            console.warn('Keine Videoszenen gefunden oder ungültiges Format:', scenesData);
          }
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der verwandten Szenen:', error);
      }
    };

    fetchRelatedScenes();
  }, [releaseId]);

  return (
    <div className="hidden lg:block max-h-screen overflow-y-auto"> {/* Setze die maximale Höhe und ermögliche Y-Scrollen */}
      <h3 className="text-xl font-bold mb-4">Weitere Szenen aus diesem Release</h3>
      {relatedScenes.length > 0 ? (
        <div className="grid grid-cols-1 gap-4">
          {relatedScenes.map((relatedScene) => (
            // Verwende hier die SzeneKarte Komponente für jede verwandte Szene
            <SzeneKarte 
              key={relatedScene.id}
              scene={relatedScene} 
              releaseId={releaseId} 
            />
          ))}
        </div>
      ) : (
        <p>Keine weiteren Szenen in diesem Release vorhanden.</p>
      )}
    </div>
  );
};

export default RelatedScenesSidebar;
