import React from 'react';

const SelectInput = ({ label, value, onChange, options, required }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2">{label}</label>
      <select
        className="select select-bordered w-full"
        value={value}
        onChange={onChange}
        required={required}
      >
        <option value="">Bitte auswählen</option>
        {options && options.length > 0 ? (
          options.map((option) => (
            <option key={option.id || option.value} value={option.id || option.value}>
              {option.attributes ? option.attributes.Name || option.attributes.titel : option.label || option.name}
            </option>
          ))
        ) : (
          <option value="" disabled>
            Keine Optionen verfügbar
          </option>
        )}
      </select>
    </div>
  );
};

export default SelectInput;

