import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import VideoUpload from './VideoUpload';
import SpielDetails from './SpielDetails';
import { tagOptions } from './constants/tagOptions';
import { kategorien } from './constants/kategorien';
import { spielstrafeOptions, persoenlicheStrafeOptions, quelleOptions } from './constants/selectOptions';
import { handleVideoUpload, deleteVideoAndThumbnail } from '../utils/videoUtils';
import VideoView from './VideoView';

const VideoszenenFormular = ({
  scene,
  spielData,
  onSubmit,
  onClose,
  loading,
  setLoading,
  error,
  success,
  setError,
  setSuccess,
  onRemoveSpiel,
  onAddSpiel,
  isNewScene
}) => {
  const [titel, setTitle] = useState(scene?.attributes?.titel || '');
  const [beschreibung, setDescription] = useState(scene?.attributes?.beschreibung || '');
  const [videoId, setVideoId] = useState(scene?.attributes?.Video?.data?.id || null); // Video ID
  const [thumbnailId, setThumbnailId] = useState(scene?.attributes?.thumbnail?.data?.id || null); // Thumbnail ID
  const [videoUrl, setVideoUrl] = useState(scene?.attributes?.Video?.data?.attributes?.url || null); // Video URL
  const [spielstrafe, setSpielstrafe] = useState(scene?.attributes?.spielstrafe || '');
  const [persoenlicheStrafe, setPersoenlicheStrafe] = useState(scene?.attributes?.persoenlicheStrafe || '');
  const [konfiRelevant, setKonfiRelevant] = useState(scene?.attributes?.konfiRelevant || false);
  const [download, setDownload] = useState(scene?.attributes?.download || false);
  const [quelle, setQuelle] = useState(scene?.attributes?.quelle || '');
  const [timecode, setTimecode] = useState(scene?.attributes?.timecode || '');
  const [tags, setTags] = useState(
    scene?.attributes?.thema
      ? scene.attributes.thema.map(tag => ({ value: tag.id, label: tag.name }))
      : []
  );
  const [bewertungen, setBewertungen] = useState({
    schiedsrichter: [],
    sra1: [],
    sra2: [],
  });
  
  const [sceneId, setSceneId] = useState(scene?.id || '');

  useEffect(() => {
    if (scene && scene.attributes.Bewertung) {
      const initialBewertungen = scene.attributes.Bewertung.map(b => ({
        id: b.id,
        sektion: b.Kategorie.startsWith('SRA1') ? 'sra1' : b.Kategorie.startsWith('SRA2') ? 'sra2' : 'schiedsrichter',
        kategorie: b.Kategorie.replace(/^SRA1: /, '').replace(/^SRA2: /, ''),
        wert: b.Bewertung
      }));
      setBewertungen({
        schiedsrichter: initialBewertungen.filter(b => b.sektion === 'schiedsrichter'),
        sra1: initialBewertungen.filter(b => b.sektion === 'sra1'),
        sra2: initialBewertungen.filter(b => b.sektion === 'sra2')
      });
    }
  }, [scene]);

  const handleBewertungChange = (sektion, index, key, value) => {
    const newBewertungen = { ...bewertungen };
    newBewertungen[sektion][index][key] = value;
    setBewertungen(newBewertungen);
  };

  const addBewertung = (sektion) => {
    setBewertungen(prev => ({ ...prev, [sektion]: [...prev[sektion], { kategorie: '', wert: '' }] }));
  };

  const removeBewertung = (sektion, index) => {
    setBewertungen(prev => ({ ...prev, [sektion]: prev[sektion].filter((_, i) => i !== index) }));
  };

  const handleTagChange = (selectedOptions) => {
    setTags(selectedOptions || []);
  };

  // Funktion zum Löschen des Videos und Thumbnails
  const handleDeleteVideo = async () => {
    try {
      setLoading(true);
      // Lösche Video und Thumbnail über die Backend-Funktion
      await deleteVideoAndThumbnail(videoId, thumbnailId);
      
      // Setze die lokalen Zustände auf null
      setVideoUrl(null);
      setVideoId(null);
      setThumbnailId(null);
      
      setSuccess('Video und Thumbnail erfolgreich gelöscht.');
    } catch (err) {
      setError('Fehler beim Löschen des Videos und Thumbnails.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Funktion zum erfolgreichen Video-Upload
  const handleSuccessfulUpload = (newVideoUrl, newVideoId, newThumbnailId) => {
    setVideoUrl(newVideoUrl);  // Setze die neue Video-URL
    setVideoId(newVideoId);    // Setze die neue Video-ID
    setThumbnailId(newThumbnailId); // Setze die neue Thumbnail-ID
  };

  return (
    <div className="p-6 bg-base-200 rounded-lg shadow-md">
        {spielData ? (
        <>
          <SpielDetails spielData={spielData} />
        </>
      ) : (
        // Zeige den "Spiel hinzufügen"-Button nur, wenn es sich nicht um eine neue Szene handelt
        !isNewScene && (
          <button
            type="button"
            className="btn btn-primary mt-4 w-full md:w-auto"
            onClick={onAddSpiel}
          >
            Spiel hinzufügen
          </button>
        )
      )}
      
     {/* Bedingte Anzeige: VideoView oder VideoUpload */}
     {videoId ? ( // Wenn eine Video-ID vorhanden ist, zeige VideoView an
        <VideoView sceneId={sceneId} onDelete={handleDeleteVideo} />
      ) : ( // Wenn keine Video-ID vorhanden ist, zeige VideoUpload an
        <VideoUpload
  handleVideoUpload={(e) => handleVideoUpload(e, setLoading, setError, setSuccess, setVideoUrl, setThumbnailId, scene)}
  loading={loading}
  videoUrl={videoUrl}
  scene={scene} // Übergibt die Szene an VideoUpload
/>

      )}

      <form onSubmit={(e) => onSubmit(e, { titel, beschreibung, spielstrafe, persoenlicheStrafe, konfiRelevant, download, quelle, timecode, tags, bewertungen })}>
        {/* Form Fields */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Titel</label>
          <input type="text" className="input input-bordered w-full" value={titel} onChange={(e) => setTitle(e.target.value)} required />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Beschreibung</label>
          <textarea className="textarea textarea-bordered w-full" value={beschreibung} onChange={(e) => setDescription(e.target.value)} required></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Spielstrafe</label>
          <Select
            options={spielstrafeOptions}
            value={spielstrafeOptions.find(option => option.value === spielstrafe)}
            onChange={(e) => setSpielstrafe(e.value)}
            placeholder="Wählen Sie eine Spielstrafe"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Persönliche Strafe</label>
          <Select
            options={persoenlicheStrafeOptions}
            value={persoenlicheStrafeOptions.find(option => option.value === persoenlicheStrafe)}
            onChange={(e) => setPersoenlicheStrafe(e.value)}
            placeholder="Wählen Sie eine persönliche Strafe"
          />
        </div>

        <div className="mb-4 flex items-center">
          <label className="mr-2">KonfiRelevant:</label>
          <input type="checkbox" className="toggle toggle-primary" checked={konfiRelevant} onChange={() => setKonfiRelevant(!konfiRelevant)} />
        </div>

        <div className="mb-4 flex items-center">
          <label className="mr-2">Herunterladbar:</label>
          <input type="checkbox" className="toggle toggle-primary" checked={download} onChange={() => setDownload(!download)} />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Quelle</label>
          <Select
            options={quelleOptions}
            value={quelleOptions.find(option => option.value === quelle)}
            onChange={(e) => setQuelle(e.value)}
            placeholder="Wählen Sie eine Quelle"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Timecode</label>
          <input type="text" className="input input-bordered w-full" value={timecode} onChange={(e) => setTimecode(e.target.value)} />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Tags</label>
          <Select
            isMulti
            options={tagOptions}
            value={tags}
            onChange={handleTagChange}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Wählen Sie Tags"
          />
        </div>

        <h3 className="text-xl font-bold mb-2">Bewertungen - Schiedsrichter</h3>
        {bewertungen.schiedsrichter.map((bewertung, index) => (
          <div key={index} className="flex mb-2 flex-wrap">
            <select
              className="select select-bordered w-full md:w-80 mb-2 mr-2"
              value={bewertung.kategorie}
              onChange={(e) => handleBewertungChange('schiedsrichter', index, 'kategorie', e.target.value)}
            >
              <option value="">Kategorie wählen</option>
              {kategorien.schiedsrichter.map((kategorie, i) => (
                <option key={i} value={kategorie}>{kategorie}</option>
              ))}
            </select>
            <input
              type="number"
              min="1"
              max="6"
              className="input input-bordered w-full md:w-14 mb-2 mr-2"
              value={bewertung.wert}
              onChange={(e) => handleBewertungChange('schiedsrichter', index, 'wert', e.target.value)}
            />
            <button type="button" className="btn btn-error w-full md:w-auto" onClick={() => removeBewertung('schiedsrichter', index)}>−</button>
          </div>
        ))}
        <button type="button" className="btn btn-primary w-full md:w-auto" onClick={() => addBewertung('schiedsrichter')}>+</button>

        {/* Bewertungen für SRA1 */}
        <h3 className="text-xl font-bold mb-2">Bewertungen - Schiedsrichterassistent 1</h3>
        {bewertungen.sra1.map((bewertung, index) => (
          <div key={index} className="flex mb-2 flex-wrap">
            <select
              className="select select-bordered w-full md:w-80 mb-2 mr-2"
              value={bewertung.kategorie}
              onChange={(e) => handleBewertungChange('sra1', index, 'kategorie', e.target.value)}
            >
              <option value="">Kategorie wählen</option>
              {kategorien.sra1.map((kategorie, i) => (
                <option key={i} value={kategorie}>{kategorie}</option>
              ))}
            </select>
            <input
              type="number"
              min="1"
              max="6"
              className="input input-bordered w-full md:w-14 mb-2 mr-2"
              value={bewertung.wert}
              onChange={(e) => handleBewertungChange('sra1', index, 'wert', e.target.value)}
            />
            <button type="button" className="btn btn-error w-full md:w-auto" onClick={() => removeBewertung('sra1', index)}>−</button>
          </div>
        ))}
        <button type="button" className="btn btn-primary w-full md:w-auto" onClick={() => addBewertung('sra1')}>+</button>

        {/* Bewertungen für SRA2 */}
        <h3 className="text-xl font-bold mb-2">Bewertungen - Schiedsrichterassistent 2</h3>
        {bewertungen.sra2.map((bewertung, index) => (
          <div key={index} className="flex mb-2 flex-wrap">
            <select
              className="select select-bordered w-full md:w-80 mb-2 mr-2"
              value={bewertung.kategorie}
              onChange={(e) => handleBewertungChange('sra2', index, 'kategorie', e.target.value)}
            >
              <option value="">Kategorie wählen</option>
              {kategorien.sra2.map((kategorie, i) => (
                <option key={i} value={kategorie}>{kategorie}</option>
              ))}
            </select>
            <input
              type="number"
              min="1"
              max="6"
              className="input input-bordered w-full md:w-14 mb-2 mr-2"
              value={bewertung.wert}
              onChange={(e) => handleBewertungChange('sra2', index, 'wert', e.target.value)}
            />
            <button type="button" className="btn btn-error w-full md:w-auto" onClick={() => removeBewertung('sra2', index)}>−</button>
          </div>
        ))}
        <button type="button" className="btn btn-primary w-full md:w-auto" onClick={() => addBewertung('sra2')}>+</button>

        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        
        <div className="flex flex-wrap justify-between">
          <button type="submit" className="btn btn-success mt-4 w-full md:w-auto" disabled={loading}>
            {loading ? 'Wird gespeichert...' : 'Speichern'}
          </button>
          <button type="button" className="btn btn-secondary mt-4 w-full md:w-auto" onClick={onClose}>
            Zurück
          </button>
        </div>
      </form>
    </div>
  );
};

export default VideoszenenFormular;
