import React from 'react';
import { useAuthContext } from './AuthProvider/AuthProvider';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import HeroLoggedIn from './HeroLoggedIn';
import Dashboard from './Dashboard';
import AktuelleSzenen from './AktuelleSzenen'; 
import AlleSzenen from './AlleSzenen';
import HeroDefault from './HeroDefault';
import PasswordResetPage from './PasswordResetPage';

const Home = () => {
  const { user } = useAuthContext(); // Check if the user is logged in

  return (
    <div className="flex justify-center">
      <Routes>
        {/* Home page (HeroLoggedIn or HeroDefault based on user authentication) */}
        <Route path="/" element={user ? <HeroLoggedIn /> : <HeroDefault />} />
        <Route path="/reset-password" element={<PasswordResetPage />} />

        {/* Login page */}
        <Route path="/login" element={<Login />} />

        {/* Protected routes that require authentication */}
        <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/aktuelle-szenen" element={user ? <AktuelleSzenen /> : <Navigate to="/login" />} />
        <Route path="/alle-szenen" element={user ? <AlleSzenen /> : <Navigate to="/login" />} />

        {/* Catch-all for undefined routes, redirect to home */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default Home;

