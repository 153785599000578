import React, { createContext, useContext, useState, useEffect } from 'react';
import { message } from 'antd';
import { getToken, setToken, removeToken } from '../utils/helpers';
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL; // Verwende die Umgebungsvariable für die API-URL

  useEffect(() => {
    const authToken = getToken();
    console.log("AuthProvider: Token aus localStorage abgerufen:", authToken);

    if (authToken) {
      console.log("AuthProvider: Token gefunden, Benutzerdaten werden abgerufen...");
      fetchLoggedInUser(authToken);
    } else {
      console.log("AuthProvider: Kein Token gefunden, Ladezustand wird beendet.");
      setIsLoading(false);
      setUserData(null);
    }
  }, []);

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("AuthProvider: Benutzerdaten erfolgreich abgerufen:", data);
        setUserData(data);
      } else {
        console.error("AuthProvider: Benutzerdaten konnten nicht abgerufen werden.");
        removeToken();
        message.error("Benutzerdaten konnten nicht abgerufen werden.");
      }
    } catch (error) {
      console.error("AuthProvider: Fehler beim Abrufen der Benutzerdaten:", error);
      message.error("Fehler beim Abrufen der Benutzerdaten.");
    } finally {
      setIsLoading(false);
      console.log("AuthProvider: Ladezustand beendet.");
    }
  };

  const login = async (identifier, password) => {
    try {
      const response = await fetch(`${apiUrl}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("AuthProvider: Login erfolgreich, Benutzerdaten:", data.user);
        setUserData(data.user);
        setToken(data.jwt);
        message.success("Erfolgreich eingeloggt.");
        console.log("AuthProvider: Token wurde in localStorage gespeichert:", data.jwt);
      } else {
        console.error("AuthProvider: Login fehlgeschlagen, ungültige Anmeldeinformationen.");
        throw new Error("Fehler bei der Anmeldung.");
      }
    } catch (error) {
      console.error("AuthProvider: Fehler beim Login:", error);
      message.error("Fehler bei der Anmeldung.");
    }
  };

  const logout = () => {
    removeToken();
    setUserData(null);
    console.log("AuthProvider: Benutzer ausgeloggt und Token entfernt.");
    message.success("Erfolgreich ausgeloggt.");
  };

  return (
    <AuthContext.Provider value={{ user: userData, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
