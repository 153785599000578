import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import VideoszeneBearbeiten from './VideoszeneBearbeiten';
import { FaLock } from 'react-icons/fa'; // Schloss-Icon
import SearchBar from './SearchBar'; // Importiere die SearchBar-Komponente

const SzenenOverview = () => {
  const [activeTab, setActiveTab] = useState('published'); // Tab-Status
  const [scenes, setScenes] = useState([]); // Szenen-Daten
  const [filteredScenes, setFilteredScenes] = useState([]); // Gefilterte Szenen-Daten
  const [selectedScene, setSelectedScene] = useState(null); // Ausgewählte Szene
  const [currentPage, setCurrentPage] = useState(1); // Aktuelle Seite für die Pagination
  const [totalScenes, setTotalScenes] = useState(0); // Gesamtzahl der Szenen
  const [searchText, setSearchText] = useState(''); // Suchtext
  const scenesPerPage = 10; // Anzahl der Szenen pro Seite
  const [lockedAlert, setLockedAlert] = useState(false); // Alert für gesperrte Szene

  // Funktion zum Laden der Szenen basierend auf Status, Suche und Pagination
  const loadScenes = async (status, page = 1, search = '') => {
    const start = (page - 1) * scenesPerPage; // Offset basierend auf der aktuellen Seite
    try {
      // API-Call, der den Status, den Titel und die Beschreibung berücksichtigt
      const query = `${process.env.REACT_APP_API_URL}/videoszenes?filters[arbeitsstatus][$eq]=${status}&filters[$or][0][titel][$containsi]=${search}&filters[$or][1][beschreibung][$containsi]=${search}&populate=*&pagination[start]=${start}&pagination[limit]=${scenesPerPage}`;
      const res = await axiosInstance.get(query, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      const scenesWithSignedThumbnails = await Promise.all(
        res.data.data.map(async (scene) => {
          if (scene.attributes.thumbnail?.data) {
            const thumbnailKey = scene.attributes.thumbnail.data.attributes.hash + '.jpg';
            const signedThumbnailRes = await axiosInstance.get(
              `${process.env.REACT_APP_API_URL}/get-signed-thumbnail-url/${thumbnailKey}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
              }
            );
            // Füge die signierte URL in die Szene-Daten ein
            scene.attributes.thumbnail.data.attributes.signedUrl = signedThumbnailRes.data.signedUrl;
          }
          return scene;
        })
      );

      // Update der Szenen-Daten und der Gesamtanzahl
      setScenes(scenesWithSignedThumbnails); // Setzt die Szenen auf die Ergebnisse des API-Aufrufs
      setFilteredScenes(scenesWithSignedThumbnails); // Gleiche Daten für den gefilterten Zustand
      setTotalScenes(res.data.meta.pagination.total); // Gesamtzahl der Szenen setzen
    } catch (err) {
      console.error('Fehler beim Laden der Szenen:', err);
    }
  };

  // Lädt Szenen basierend auf dem aktiven Tab, der aktuellen Seite und dem Suchtext
  useEffect(() => {
    loadScenes(activeTab, currentPage, searchText);
  }, [activeTab, currentPage, searchText]);

  // Funktion zum Entsperren der aktuellen Szene
  const unlockScene = async (scene) => {
    if (!scene || !scene.id) return; // Falls keine Szene vorhanden ist, mache nichts

    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/videoszenes/${scene.id}`,
        { data: { locked: false } },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
      console.log(`Szene ${scene.id} wurde entsperrt.`);
    } catch (err) {
      console.error(`Fehler beim Entsperren der Szene mit ID ${scene.id}:`, err);
    }
  };

  // Entsperre Szene bei Tab-Wechsel oder Seitenwechsel
  useEffect(() => {
    return () => {
      if (selectedScene) {
        unlockScene(selectedScene);
      }
    };
  }, [activeTab, currentPage, selectedScene]);

  // Funktion für das Schließen der Bearbeitung und das Entsperren der Szene
  const handleClose = async (scene) => {
    await unlockScene(scene);
    setSelectedScene(null); // Zurück zur Szenenübersicht
    handleUpdate(); // Szenenliste neu laden
  };

  // Funktion zum Laden der Szenenliste nach dem Bearbeiten
  const handleUpdate = () => {
    loadScenes(activeTab, currentPage, searchText); // Szenen neu laden
  };

  // Funktion, um eine Szene als "locked" zu setzen
  const lockScene = async (scene) => {
    try {
      console.log(`Versuche, Szene ${scene.id} zu sperren...`);
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/videoszenes/${scene.id}`,
        { data: { locked: true } },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
      console.log(`Szene ${scene.id} wurde gesperrt.`);
    } catch (err) {
      console.error('Fehler beim Sperren der Szene:', err);
      throw new Error('Szene konnte nicht gesperrt werden.');
    }
  };

  // Funktion für das Klicken auf eine Szene mit erneuter Überprüfung des locked-Status
  const handleSceneClick = async (scene) => {
    try {
      // Entsperre die vorherige Szene, bevor eine neue geöffnet wird
      if (selectedScene && selectedScene.id !== scene.id) {
        await unlockScene(selectedScene);
      }

      console.log(`Überprüfe Szene mit ID: ${scene.id}...`);
      const { data } = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/videoszenes/${scene.id}`);
      if (data.data.attributes.locked) {
        console.warn(`Szene ${scene.id} ist bereits gesperrt.`);
        setLockedAlert(true);
        return;
      }

      // Sperre die neue Szene
      await lockScene(scene);
      setSelectedScene(scene);
    } catch (err) {
      console.error(`Fehler beim Überprüfen oder Sperren der Szene mit ID ${scene.id}:`, err);
    }
  };

  // Funktion zur Handhabung der Seitenänderung
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // Szenen neu laden
    setScenes([]);
  };

  const totalPages = Math.ceil(totalScenes / scenesPerPage);

  if (selectedScene) {
    return (
      <VideoszeneBearbeiten
        scene={selectedScene}
        onClose={() => handleClose(selectedScene)} // Szene entsperren und schließen
        onUpdate={handleUpdate} // Szenen neu laden nach Bearbeitung
      />
    );
  }

  return (
    <div>
      {/* Alert-Komponente für gesperrte Szene */}
      {lockedAlert && (
        <div className="alert alert-error shadow-lg mb-4">
          <div>
            <span>Diese Szene ist bereits gesperrt und kann nicht bearbeitet werden.</span>
            <button className="btn btn-sm btn-ghost" onClick={() => setLockedAlert(false)}>Schließen</button>
          </div>
        </div>
      )}

      {/* Suchleiste hinzufügen */}
      <SearchBar searchText={searchText} setSearchText={setSearchText} />

      <div className="flex justify-center mb-4">
        <div className="tabs tabs-boxed">
          <a
            role="tab"
            className={`tab ${activeTab === 'published' ? 'tab-active' : ''}`}
            onClick={async () => {
              await unlockScene(selectedScene); // Entsperre aktuelle Szene, wenn eine neue ausgewählt wird
              setSelectedScene(null);
              setActiveTab('published');
              setCurrentPage(1);
              setScenes([]); // Szenenliste zurücksetzen
            }}
          >
            Veröffentlicht
          </a>
          <a
            role="tab"
            className={`tab ${activeTab === 'geschnitten' ? 'tab-active' : ''}`}
            onClick={async () => {
              await unlockScene(selectedScene); // Entsperre aktuelle Szene, wenn eine neue ausgewählt wird
              setSelectedScene(null);
              setActiveTab('geschnitten');
              setCurrentPage(1);
              setScenes([]); // Szenenliste zurücksetzen
            }}
          >
            Geschnitten
          </a>
          <a
            role="tab"
            className={`tab ${activeTab === 'erstellt' ? 'tab-active' : ''}`}
            onClick={async () => {
              await unlockScene(selectedScene); // Entsperre aktuelle Szene, wenn eine neue ausgewählt wird
              setSelectedScene(null);
              setActiveTab('erstellt');
              setCurrentPage(1);
              setScenes([]); // Szenenliste zurücksetzen
            }}
          >
            Erstellt
          </a>
        </div>
      </div>

      <div className="scene-list">
        {filteredScenes.map((scene) => (
          <div
            key={scene.id}
            className={`flex flex-col md:flex-row items-start md:items-center mb-4 p-4 border rounded ${scene.attributes.locked ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={() => handleSceneClick(scene)}
          >
            <img
              src={scene.attributes.thumbnail?.data?.attributes?.signedUrl || scene.attributes.thumbnail?.data?.attributes?.url}
              alt={scene.attributes.titel}
              className="w-full md:w-48 h-32 object-cover mb-2 md:mb-0 mr-0 md:mr-4 rounded"
            />
            <div className="flex flex-col justify-between w-full">
              <div>
                <h3 className="text-lg md:text-xl font-semibold">{scene.attributes.titel}</h3>
                <p className="text-sm text-gray-600 mt-2">{scene.attributes.beschreibung?.substring(0, 100)}...</p>
              </div>
              <div className="flex justify-end mt-2">
                {scene.attributes.thema && (
                  <span className="badge badge-primary text-xs">{scene.attributes.thema[0]?.name || 'Thema'}</span>
                )}
              </div>
            </div>
            {scene.attributes.locked && (
              <FaLock className="ml-2 text-red-500" />
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-4">
        <div className="join">
          {Array.from({ length: totalPages }, (_, index) => (
            <input
              key={index + 1}
              className={`join-item btn btn-square ${currentPage === index + 1 ? 'btn-active' : ''}`}
              type="radio"
              name="pagination"
              aria-label={`${index + 1}`}
              checked={currentPage === index + 1}
              onChange={() => handlePageChange(index + 1)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SzenenOverview;
