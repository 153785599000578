import axiosInstance from "../components/axiosConfig";

export const handleSave = async (
  e,
  scene,  // Szene-Objekt kann null oder undefined sein, wenn es sich um eine neue Szene handelt
  titel,
  beschreibung,
  spielstrafe,
  persoenlicheStrafe,
  konfiRelevant,
  download,
  quelle,
  timecode,
  tags,
  bewertungen,
  spielId,  // Spiel-ID sicherstellen
  setLoading,
  setError,
  setSuccess,
  onUpdate
) => {
  e.preventDefault();
  setLoading(true);
  setError(null);
  setSuccess(null);

  try {
    // Stelle sicher, dass die Spiel-ID vorhanden ist
    console.log("Spiel-ID beim Speichern:", spielId); // Log der Spiel-ID
    if (!spielId) {
      setError("Keine gültige Spiel-ID gefunden.");
      setLoading(false);
      return;
    }

    // Erstellen des Themas: Verwende nur Tags mit einer gültigen ID
    const themaObjects = tags.map((tag) => {
      if (typeof tag.value === "number") {
        return { id: tag.value, name: tag.label };
      } else {
        return { name: tag.label };
      }
    });

    // Daten für den ersten Aufruf (Erstellen/Aktualisieren der Szene)
    const dataToSend = {
      titel,
      beschreibung,
      spielstrafe,
      persoenlicheStrafe,
      konfiRelevant,
      download,
      quelle,
      timecode,
      thema: themaObjects,
      spiel: spielId, // Spiel-ID sicherstellen
    };

    console.log("Daten, die gesendet werden:", dataToSend); // Log für das gesamte Objekt

    let response;
    let sceneId;

    // Überprüfe, ob die Szene bereits eine ID hat (PUT) oder nicht (POST)
    if (scene && scene.id) {
      console.log("Aktualisiere bestehende Szene:", scene.id);
      response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/videoszenes/${scene.id}`,
        { data: dataToSend },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      sceneId = scene.id;  // Verwende die bereits existierende Szene-ID
      setSuccess("Videoszene erfolgreich aktualisiert!");
    } else {
      // Wenn eine neue Szene erstellt wird, setze den Arbeitsstatus auf "erstellt"
      console.log("Erstelle neue Szene mit Daten:", dataToSend);
      response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/videoszenes`,
        {
          data: {
            ...dataToSend,
            arbeitsstatus: "erstellt", // Arbeitsstatus auf "erstellt" setzen
            locked: false,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      sceneId = response.data.data.id;  // Erhalte die neue Szene-ID
      console.log("Neue Szene erstellt, ID:", sceneId); // Log der Szene-ID
      setSuccess("Neue Videoszene erfolgreich erstellt!");
    }

    console.log("Bewertungen erhalten:", bewertungen);

    // Log zu den Bewertungen
    if (bewertungen && Object.keys(bewertungen).length > 0) {
      const bewertungenData = Object.entries(bewertungen).flatMap(([sektion, items]) =>
        items.map((b) => ({
          Kategorie:
            sektion === "sra1"
              ? `SRA1: ${b.kategorie}`
              : sektion === "sra2"
              ? `SRA2: ${b.kategorie}`
              : b.kategorie,
          Bewertung: parseInt(b.wert, 10),
        }))
      );

      console.log("Formatiertes Bewertungs-Array, das gesendet wird:", bewertungenData);

      // Aktualisiere die Szene mit den Bewertungen
      const bewertungUpdateResponse = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/videoszenes/${sceneId}`,
        {
          data: {
            Bewertung: bewertungenData, // Bewertungen hinzufügen
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      console.log("Bewertungen erfolgreich aktualisiert:", bewertungUpdateResponse.data);
      setSuccess("Neue Videoszene und Bewertungen erfolgreich gespeichert!");
    } else {
      console.warn("Keine Bewertungen zum Speichern gefunden.");
    }

    // Führe den Callback aus, um die Szene zu aktualisieren
    if (onUpdate) onUpdate();
  } catch (err) {
    console.error("Fehler beim Speichern der Videoszene:", err);
    if (err.response) {
      setError(
        `Fehler beim ${
          scene ? "Aktualisieren" : "Erstellen"
        } der Videoszene: ${
          err.response.data.error.message || "Unbekannter Fehler"
        }`
      );
    } else {
      setError(
        `Fehler beim ${scene ? "Aktualisieren" : "Erstellen"} der Videoszene`
      );
    }
  } finally {
    setLoading(false);
  }
};


export const updateScene = async (sceneId, updatedAttributes) => {
  try {
    console.log(`Aktualisiere Szene mit ID ${sceneId}...`);
    console.log('Neue Attribute:', updatedAttributes);

    // Führe den API-Aufruf zum Aktualisieren der Szene durch
    const response = await axiosInstance.put(`/videoszenes/${sceneId}`, {
      data: updatedAttributes
    });

    console.log('Szene erfolgreich aktualisiert:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Aktualisieren der Szene:', error);
    throw error;
  }
};


