import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from './AuthProvider/AuthProvider';
import { setView } from '../redux/viewSlice';
import { message } from 'antd';  // Stelle sicher, dass die Benachrichtigung korrekt importiert ist

const Login = () => {
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginResponse = await login(identifier, password);

      // Prüfe, ob der Benutzer erfolgreich eingeloggt wurde und nicht blockiert ist
      if (loginResponse && !loginResponse.blocked) {
        dispatch(setView('Home')); // Status auf 'Home' setzen
        navigate('/'); // Weiterleitung zur Startseite
      } else if (loginResponse && loginResponse.blocked) {
        // Der Benutzer ist blockiert, also keine Weiterleitung
        message.error("Ihr Konto ist inaktiv. Bitte kontaktieren Sie den Support.");
      }
    } catch (error) {
      console.error('Login failed:', error);
      message.error('Fehler bei der Anmeldung.');
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="w-11/12 md:w-11/12 lg:w-11/12 xl:w-11/12 flex shadow-lg rounded-lg bg-white">
        <div className="hidden md:block md:w-1/2">
          <img
            src="/path/to/your/image.png"
            alt="Landscape"
            className="h-full w-full object-cover rounded-l-lg"
          />
        </div>
        <div className="w-full md:w-1/2 p-6">
          <h2 className="text-2xl font-bold text-center mb-8">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-control mb-4">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                type="text"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                placeholder="Email"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control mb-4">
              <label className="label">
                <span className="label-text">Password</span>
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="input input-bordered w-full"
              />
            </div>
            <button type="submit" className="btn btn-primary w-full">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

