import axiosInstance from "../axiosConfig";
import qs from 'qs'; // Importiere die qs Library

// Ligen laden
export const fetchLigen = async () => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/ligas`);
  return res.data.data;
};

// Saisons laden
export const fetchSaisons = async () => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/saisons`);
  return res.data.data;
};

// Mannschaften basierend auf der Liga und Saison laden
export const fetchMannschaften = async (liga, saison) => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/ligazuordnungen`, {
    params: {
      liga: liga,
      saison: saison,
      populate: ['verein', 'liga', 'saison'],
    },
  });
  const validMannschaften = res.data.data
    .filter(zuordnung => zuordnung.attributes && zuordnung.attributes.verein && zuordnung.attributes.verein.data)
    .map(zuordnung => ({
      id: zuordnung.attributes.verein.data.id,
      name: zuordnung.attributes.verein.data.attributes.name,
    }));
  return validMannschaften;
};

// Überprüfen, ob das Spiel bereits existiert
export const checkIfSpielExistiert = async (liga, saison, heimmannschaft, gastmannschaft) => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/spiels`, {
    params: {
      filters: {
        $and: [
          { heimverein: { $eq: heimmannschaft } },
          { gastverein: { $eq: gastmannschaft } },
          { liga: { $eq: liga } },
          { saison: { $eq: saison } },
        ],
      },
      populate: '*',
    },
  });
  return res.data.data;
};

// Spiel anlegen
export const createSpiel = async (ligaId, saisonId, heimmannschaftId, gastmannschaftId, spieltag) => {
  try {
    // Spieltag nur dann senden, wenn es eine gültige Zahl ist
    const spielData = {
      heimverein: { id: heimmannschaftId },
      gastverein: { id: gastmannschaftId },
      liga: { id: ligaId },
      saison: { id: saisonId },
    };

    // Wenn der Spieltag eine Zahl ist, füge ihn zur Anfrage hinzu
    if (spieltag && !isNaN(spieltag)) {
      spielData.Spieltag = Number(spieltag);  // Stelle sicher, dass der Spieltag eine Zahl ist
    }

    const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/spiels`, { data: spielData });

    return response.data.data.id;  // Rückgabe der ID des neuen Spiels
  } catch (error) {
    console.error('Fehler beim Anlegen des Spiels:', error);
    throw error;
  }
};



export const createVideoszene = async (payload) => {
  try {
    // Erstellen des Themas: Verwende nur Tags mit einer gültigen ID oder den Namen, falls es neue Themen sind
    const themaObjects = payload.thema.map(tag => {
      // Falls `tag.id` ein String ist, verwenden wir es als Namen
      if (typeof tag.id === 'string') {
        return { name: tag.id };  // `id` enthält hier den Namen des Themas
      } else if (tag.id) {
        // Falls `id` eine Nummer ist, verwenden wir es als vorhandene ID
        return { id: tag.id };
      } else {
        // Falls kein ID-Feld vorhanden ist, verwenden wir den Namen direkt
        return { name: tag.label || tag.name };
      }
    });

    // Erstellen des Bewertung-Arrays
    const bewertungenObjects = Object.entries(payload.bewertungen).flatMap(([sektion, items]) => {
      // Sicherstellen, dass `items` ein Array ist, bevor `map()` aufgerufen wird
      if (!Array.isArray(items)) {
        return [];  // Falls `items` kein Array ist, gib ein leeres Array zurück
      }

      return items.map(b => ({
        id: b.id || undefined,  // Falls die Bewertung bereits existiert, sonst undefined
        Kategorie: sektion === 'sra1' ? `SRA1: ${b.kategorie}` : sektion === 'sra2' ? `SRA2: ${b.kategorie}` : b.kategorie,
        Bewertung: parseInt(b.wert, 10)
      }));
    });

    // Payload, das an die API gesendet wird
    const dataToSend = {
      titel: payload.titel,
      beschreibung: payload.beschreibung,
      Video: payload.Video,
      thumbnail: payload.thumbnail,
      spiel: payload.spiel,
      arbeitsstatus: payload.arbeitsstatus,
      spielstrafe: payload.spielstrafe,
      persoenlicheStrafe: payload.persoenlicheStrafe,
      konfiRelevant: payload.konfiRelevant,
      download: payload.download,
      quelle: payload.quelle,
      timecode: payload.timecode,
      thema: themaObjects,  // Die erstellten Themenobjekte
      Bewertung: bewertungenObjects  // Die Bewertungen-Objekte
    };

    console.log(dataToSend);

    // API-Aufruf zur Erstellung der Videoszene
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/videoszenes`,
      { data: dataToSend },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        }
      }
    );

    return res;
  } catch (err) {
    console.error('Fehler beim Erstellen der Videoszene oder Bewertungen:', err);
    throw err;
  }
};

// api/api.js
export const fetchVereineByLigaUndSaison = async (ligaId, saisonId) => {
  try {
    const response = await axiosInstance.get(`/ligazuordnungen/?populate=*&filters[liga][id][$eq]=${ligaId}&filters[saison][id][$eq]=${saisonId}`);
    
    // Die Daten kommen direkt im JSON-Format
    const data = response.data;

    // Filtere und mappe die Daten, um nur die relevanten Informationen (Vereine) zu erhalten
    return data.data.map(item => ({
      id: item.attributes.verein.data.id,
      name: item.attributes.verein.data.attributes.name
    }));
  } catch (error) {
    console.error('Fehler beim Laden der Vereine:', error);
    return [];
  }
};

export const fetchAllVideoszenes = async () => {
  let allScenes = [];
  let page = 1;
  let moreData = true;

  try {
    while (moreData) {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/videoszenes`, {
        params: {
          filters: {
            arbeitsstatus: { $eq: 'published' }
          },
          populate: '*',
          pagination: { page, pageSize: 50 } // Größere pageSize festlegen, um weniger Abfragen zu machen
        },
        paramsSerializer: params => qs.stringify(params, { encodeValuesOnly: true, arrayFormat: 'brackets' })
      });

      if (response.data && response.data.data) {
        allScenes = allScenes.concat(response.data.data);
        page += 1;
        moreData = response.data.data.length > 0;
      } else {
        moreData = false;
      }
    }
    return allScenes;
  } catch (error) {
    console.error('Fehler beim Abrufen aller Videoszenen:', error);
    return [];
  }
};
