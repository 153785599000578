import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axiosInstance from './axiosConfig';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registriere die notwendigen Skalen und Elemente
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ViewsLast30Days = () => {
  const [chartData, setChartData] = useState(null); // Chart-Daten initial auf null setzen

  useEffect(() => {
    const fetchAllViews = async () => {
      try {
        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        
        const isoDate = thirtyDaysAgo.toISOString();

        let allViewsData = [];
        let page = 1;
        let hasMoreData = true;

        while (hasMoreData) {
          const response = await axiosInstance.get('/videoszene-views', {
            params: {
              'filters[viewedAt][$gte]': isoDate,
              'pagination[page]': page,
              'pagination[pageSize]': 100,  // Anzahl der Elemente pro Seite anpassen
              'populate': '*'
            }
          });

          const viewsData = response.data.data;
          allViewsData = allViewsData.concat(viewsData);
          
          // Überprüfen, ob es mehr Seiten gibt
          const pagination = response.data.meta.pagination;
          hasMoreData = page < pagination.pageCount;
          page += 1;
        }

        const last30DaysData = processLast30DaysData(allViewsData);
        setChartData({
          labels: last30DaysData.dates,
          datasets: [{
            label: 'Views',
            data: last30DaysData.viewCounts,
            fill: false,
            borderColor: 'rgba(75, 192, 192, 1)',
            tension: 0.1
          }]
        });
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };

    fetchAllViews();
  }, []);

  const processLast30DaysData = (viewsData) => {
    const today = new Date();
    const last30Days = [];

    // Erstellt ein Array mit den letzten 30 Tagen
    for (let i = 0; i < 30; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      last30Days.push(date.toISOString().split('T')[0]); // Format yyyy-mm-dd
    }

    // Initialisiert ein Objekt, um die Views pro Tag zu speichern
    const viewCountsByDate = last30Days.reduce((acc, date) => {
      acc[date] = 0;
      return acc;
    }, {});

    // Durchlaufe alle Views und gruppiere sie nach dem viewedAt-Datum
    viewsData.forEach(view => {
      const viewedAtDate = view.attributes.viewedAt.split('T')[0]; // Extrahiert das Datum aus dem Timestamp
      if (viewCountsByDate[viewedAtDate] !== undefined) {
        viewCountsByDate[viewedAtDate] += 1; // Zählt die Anzahl der Views für das Datum
      }
    });

    // Extrahiere die Daten in separate Arrays für das Chart
    const dates = Object.keys(viewCountsByDate).reverse(); // Die letzten 30 Tage
    const viewCounts = Object.values(viewCountsByDate).reverse(); // Die Anzahl der Views für jeden Tag
    console.log(viewCounts);
    return { dates, viewCounts };
  };

  if (!chartData) {
    return <div>Lade Daten...</div>; // Platzhalter, während die Daten geladen werden
  }

  return (
    <div className="card bg-base-100 shadow-md p-4">
      <h2 className="card-title">Views der letzten 30 Tage</h2>
      <Line data={chartData} />
    </div>
  );
};

export default ViewsLast30Days;
