import React from 'react';

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  const generatePageNumbers = () => {
    const pages = [];
    
    // Ersten zwei Seiten anzeigen
    if (currentPage > 3) {
      pages.push(1, 2);
      if (currentPage > 4) pages.push('...');
    }
    
    // Zwei Seiten vor und nach der aktuellen Seite anzeigen
    for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
      pages.push(i);
    }

    // Letzten zwei Seiten anzeigen
    if (currentPage < totalPages - 2) {
      if (currentPage < totalPages - 3) pages.push('...');
      pages.push(totalPages - 1, totalPages);
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="mt-6 flex justify-center">
      <div className="join">
        {pageNumbers.map((page, index) => (
          typeof page === 'number' ? (
            <button
              key={index}
              className={`join-item btn ${currentPage === page ? 'btn-primary' : ''}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ) : (
            <button key={index} className="join-item btn btn-disabled">...</button>
          )
        ))}
      </div>
    </div>
  );
};

export default Pagination;
