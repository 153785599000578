import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig';
import SzeneKarte from './SzeneKarte';

const SimilarScenesSidebar = ({ themes, similarScenes }) => {
    const [relatedScenes, setRelatedScenes] = useState([]);

    useEffect(() => {
        const fetchRelatedScenes = async () => {
            // Prüfe, ob similarScenes ein Array ist und Elemente enthält
            if (Array.isArray(similarScenes) && similarScenes.length > 0) {
                try {
                    const promises = similarScenes.map(id => 
                        axiosInstance.get(`${process.env.REACT_APP_API_URL}/videoszenes/${id}?populate=thumbnail`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                            },
                        })
                    );

                    const responses = await Promise.all(promises);
                    const validScenes = responses
                        .map(response => response.data?.data)
                        .filter(scene => scene !== undefined); // Filtere nur definierte Szenen

                    // Setze maximal 10 verwandte Szenen
                    setRelatedScenes(validScenes.slice(0, 10));
                } catch (error) {
                    console.error('Fehler beim Abrufen der verwandten Szenen:', error.message, error);
                    setRelatedScenes([]); // Setze ein leeres Array bei Fehlern
                }
            } else {
                console.log("similarScenes ist entweder undefiniert oder enthält keine Szenen.");
                setRelatedScenes([]); // Setze leeres Array bei ungültigem similarScenes
            }
        };

        fetchRelatedScenes();
    }, [similarScenes]); // Abhängigkeit von 'similarScenes'

    return (
        <div className="hidden lg:block max-h-screen overflow-y-auto">
            <h3 className="text-xl font-bold mb-4">Ähnliche Szenen</h3>
            {relatedScenes.length > 0 ? (
                <div className="grid grid-cols-1 gap-4">
                    {relatedScenes.map(relatedScene => (
                        <SzeneKarte key={relatedScene.id} scene={relatedScene} />
                    ))}
                </div>
            ) : (
                <p>Keine ähnlichen Szenen vorhanden</p>
            )}
        </div>
    );
};

export default SimilarScenesSidebar;
