import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleForm } from '../redux/videoSceneSlice';
import NeueVideoszene from './NeueVideoszene';
import SzenenOverview from './SzenenOverview'; // Importiere SzenenOverview

const VideoszenenVerwalten = () => {
  const dispatch = useDispatch();
  const showForm = useSelector((state) => state.videoScene.showForm);
  
  const [isVisible, setIsVisible] = useState(true); // Zustand für die Sichtbarkeit der Verwaltungskomponente

  const handleButtonClick = () => {
    dispatch(toggleForm());
  };

  const handleNewSceneCreated = () => {
    // Wenn eine neue Szene erfolgreich erstellt wurde, die Verwaltungskomponente ausblenden
    setIsVisible(false);
  };

  return isVisible ? (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Videoszenen verwalten</h1>
      <button className="btn btn-primary mb-4" onClick={handleButtonClick}>
        {showForm ? 'Formular schließen' : 'Neue Videoszene erstellen'}
      </button>

      {showForm ? (
        <NeueVideoszene onSceneCreated={handleNewSceneCreated} />
      ) : (
        <SzenenOverview /> // Zeige Szenenübersicht nur, wenn das Formular nicht sichtbar ist
      )}
    </div>
  ) : (
    <div className="p-6 bg-green-100 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold">Neue Videoszene erfolgreich erstellt!</h1>
      <button
        className="btn btn-primary mt-4"
        onClick={() => setIsVisible(true)} // Verwaltung wieder anzeigen, falls der Benutzer zurück möchte
      >
        Zurück zur Verwaltung
      </button>
    </div>
  );
};

export default VideoszenenVerwalten;


