import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';

const Zugehoerigkeiten = () => {
  const [data, setData] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState('');
  const [ligas, setLigas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newVerein, setNewVerein] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedVerein, setSelectedVerein] = useState(null);

  useEffect(() => {
    const fetchSeasonsAndLigas = async () => {
      try {
        const seasonsResponse = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/saisons`);
        setSeasons(seasonsResponse.data.data);
        
        const ligasResponse = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/ligas`);
        setLigas(ligasResponse.data.data);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching seasons or ligas:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchSeasonsAndLigas();
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/ligazuordnungen`, {
            params: {
              populate: 'verein,liga,saison',
              'filters[saison][id][$eq]': selectedSeason,
              'pagination[limit]': 1000, // Setzt das Limit auf 100 Einträge oder einen passenden Wert
              'pagination[start]': 0,    // Startet bei Eintrag 0
            }
          });          
          setData(response.data.data);
          console.log(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError(error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedSeason]);

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  const handleSearchVerein = async (query) => {
    setNewVerein(query);
    if (query.length < 3) {
      setSearchResults([]);
      return;
    }
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/vereins`, {
        params: {
          'filters[name][$containsi]': query,
          'pagination[limit]': 3, // Nur die Top 3 Ergebnisse anzeigen
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      setSearchResults(response.data.data);
    } catch (error) {
      console.error('Fehler bei der Vereinssuche:', error);
    }
  };

  const handleSelectVerein = (verein) => {
    setSelectedVerein(verein);
    setNewVerein(verein.attributes.name);
    setSearchResults([]);
  };

  const handleAddVerein = async (ligaId) => {
    if (!selectedVerein) {
      setError('Bitte wählen Sie einen Verein aus der Liste aus.');
      return;
    }
    try {
      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/ligazuordnungen`, {
        data: {
          verein: selectedVerein.id,
          liga: ligaId,
          saison: selectedSeason,
        }
      });
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/ligazuordnungen`, {
        params: {
          populate: 'verein,liga,saison',
          'filters[saison][id][$eq]': selectedSeason,
        }
      });
      setData(response.data.data);
      setNewVerein('');
      setSelectedVerein(null);
    } catch (error) {
      console.error('Error adding verein:', error);
      setError(error);
    }
  };

  const handleDeleteVerein = async (zuordnungId) => {
    try {
      await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/ligazuordnungen/${zuordnungId}`);
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/ligazuordnungen`, {
        params: {
          populate: 'verein,liga,saison',
          'filters[saison][id][$eq]': selectedSeason,
        }
      });
      setData(response.data.data);
    } catch (error) {
      console.error('Error deleting verein:', error);
      setError(error);
    }
  };

  return (
    <div>
      <h1 className="text-xl font-bold mb-4">Vereinszugehörigkeiten</h1>
      
      {/* Dropdown zur Auswahl der Saison */}
      <select 
        className="select select-bordered w-full max-w-xs mb-4" 
        value={selectedSeason}
        onChange={handleSeasonChange}
      >
        <option disabled selected value="">Wähle eine Saison</option>
        {seasons.map((season) => (
          <option key={season.id} value={season.id}>
            {season.attributes.titel}
          </option>
        ))}
      </select>

      {/* Lade- und Fehlermeldungen */}
      {loading && <p>Loading...</p>}
      {error && <p>Error loading data: {error.message}</p>}

      {/* Accordions für jede Liga */}
      {!loading && !error && ligas.length > 0 && ligas.map((liga) => (
        <div key={liga.id} className="collapse collapse-arrow bg-base-200 mb-4">
          <input type="radio" name="my-accordion" />
          <div className="collapse-title text-xl font-medium">
            {liga.attributes.Name}
          </div>
          <div className="collapse-content">
            <ul>
              {data
                .filter(item => item.attributes.liga.data.id === liga.id)
                .map(item => (
                  <li key={item.id} className="flex items-center justify-between">
                    <span>{item.attributes.verein.data.attributes.name}</span>
                    <button
                      className="btn btn-square btn-outline"
                      onClick={() => handleDeleteVerein(item.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </li>
                ))}
            </ul>
            <div className="mt-4">
              <input 
                type="text" 
                placeholder="Verein suchen" 
                className="input input-bordered w-full max-w-xs mb-2" 
                value={newVerein} 
                onChange={(e) => handleSearchVerein(e.target.value)} 
              />
              {searchResults.length > 0 && (
                <ul className="menu bg-base-100 rounded-box shadow-md mb-2">
                  {searchResults.map((verein) => (
                    <li key={verein.id} onClick={() => handleSelectVerein(verein)}>
                      <a>{verein.attributes.name}</a>
                    </li>
                  ))}
                </ul>
              )}
              <button 
                className="btn btn-primary" 
                onClick={() => handleAddVerein(liga.id)}
              >
                Verein hinzufügen
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Zugehoerigkeiten;
