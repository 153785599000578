import axiosInstance from '../axiosConfig';

// Funktion zum Abrufen der Benutzerinformationen
const fetchUserData = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}`, // Annahme: Dies ist die Route zum Abrufen der Benutzerinformationen
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Benutzerinformationen:', error);
    return null;
  }
};

// Funktion zum Abrufen der Szenen- und Videodaten
export const fetchSceneData = async (id, setScene, setGameData, setSignedVideoUrl) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/videoszenes/${id}?populate=spiel.heimverein,spiel.gastverein,spiel.liga,Video,thumbnail,Bewertung`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );
    const sceneData = response.data.data;
    setScene(sceneData);

    // Extrahiere die Spieldaten, falls verfügbar
    const spielData = sceneData.attributes.spiel?.data;
    if (spielData) {
      setGameData(spielData.attributes);
    }

    // Wenn ein Video vorhanden ist, hole die signierte Video-URL
    if (sceneData.attributes.Video?.data) {
      const videoHash = sceneData.attributes.Video.data.attributes.hash;
      const videoExt = sceneData.attributes.Video.data.attributes.ext;
      const videoKey = videoHash + videoExt;

      const signedVideoRes = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/get-signed-video-url/${videoKey}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      setSignedVideoUrl(signedVideoRes.data.signedUrl); // Setze die signierte Video-URL
    }
  } catch (error) {
    console.error('Fehler beim Laden der Szene:', error);
  }
};

// Funktion zum Prüfen, ob die Szene bereits als gesehen markiert wurde
export const checkExistingUserScene = async (userId, id) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/user-szenens?filters[user][id][$eq]=${userId}&filters[videoszene][id][$eq]=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );

    if (response.data.data.length > 0) {
      console.log('Eintrag existiert bereits:', response.data.data[0]);
      return response.data.data[0].id; // Gib die ID des bestehenden Eintrags zurück
    }

    return null; // Kein Eintrag vorhanden
  } catch (error) {
    console.error('Fehler beim Prüfen des Eintrags:', error);
    return null;
  }
};

// Funktion zum Speichern oder Aktualisieren des User-Szene-Eintrags
export const saveOrUpdateUserScene = async (userId, id) => {
  try {
    // Abrufen der Benutzerinformationen, um den Wert von "save_view" zu prüfen
    const userData = await fetchUserData(userId);
    if (!userData || !userData.save_view) {
      console.log('User hat save_view auf false gesetzt. Speichern wird übersprungen.');
      return; // Speichern überspringen, wenn save_view auf false gesetzt ist
    }

    const existingEntryId = await checkExistingUserScene(userId, id);

    if (existingEntryId) {
      // Eintrag existiert bereits, aktualisieren
      console.log('Aktualisiere bestehenden Eintrag:', existingEntryId);
      const dataToUpdate = {
        viewed_at: new Date().toISOString(),
      };
      console.log('Daten zum Aktualisieren:', dataToUpdate);

      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/user-szenens/${existingEntryId}`,
        { data: dataToUpdate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          }
        }
      );
    } else {
      // Eintrag existiert nicht, neuen Eintrag erstellen
      const dataToSave = {
        viewed_at: new Date().toISOString(),
        user: { id: userId }, // Benutzer-ID als Relation übergeben
        videoszene: id,
      };
      console.log('Daten zum Speichern:', dataToSave);

      await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/user-szenens`,
        { data: dataToSave },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          }
        }
      );
    }
  } catch (error) {
    console.error('Fehler beim Speichern/Aktualisieren des User-Szene-Eintrags:', error);
  }
};
