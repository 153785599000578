import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axiosInstance from './axiosConfig';

// Optionen für die Rollen
const roleOptions = [
  { value: 'VSA', label: 'VSA' },
  { value: 'BSA', label: 'BSA' },
  { value: 'BEO', label: 'BEO' },
  { value: 'SR', label: 'SR' },
  { value: 'LW', label: 'LW' },
  { value: 'GSO', label: 'GSO' },
  { value: 'GSA', label: 'GSA' },
  { value: 'KT', label: 'KT' }
];

const EditUserModal = ({ user, districts, onClose, onUpdate }) => {
  const [updatedUser, setUpdatedUser] = useState({ ...user });
  const [ligatypOptions, setLigatypOptions] = useState([]); // Neuer State für Ligatypen

  useEffect(() => {
    if (user) {
      setUpdatedUser({
        ...user,
        sr_rolle: user.sr_rolle.map(role => ({ id: role.id, rolle: role.rolle })), // Mapped to array of objects
        bezirk: user.bezirk ? user.bezirk.id : null,
        ligatyp: user.ligatyp ? user.ligatyp.id : null, // Initialisiere Ligatyp mit ID
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchLigatyps = async () => {
      try {
        const response = await axiosInstance.get('/ligatyps');
        const ligatyps = response.data.data; // Annahme: die Daten sind im "data"-Feld
        const options = ligatyps.map(ligatyp => ({
          value: ligatyp.id,
          label: ligatyp.attributes.Name // Hier anpassen, falls der Name anders heißt
        }));
        setLigatypOptions(options);
      } catch (error) {
        console.error('Fehler beim Abrufen der Ligatypen:', error);
      }
    };

    fetchLigatyps(); // Funktion aufrufen, um Ligatypen abzurufen
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleRoleChange = (selectedOptions) => {
    const roles = selectedOptions
      ? selectedOptions.map(option => ({ id: option.id || undefined, rolle: option.value }))
      : [];
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      sr_rolle: roles, // Aktualisiere sr_rolle korrekt
    }));
  };

  const handleDistrictChange = (selectedOption) => {
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      bezirk: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleLigatypChange = (selectedOption) => {
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      ligatyp: selectedOption ? selectedOption.value : null,
    }));
  };

  // Funktion, um den Benutzer inaktiv zu setzen
  const handleSetInactive = async () => {
    try {
      const userData = { 
        blocked: true, // Setze den Benutzer auf "blocked"
      };
      console.log(userData);
      await axiosInstance.put(`${process.env.REACT_APP_API_URL}/users/${updatedUser.id}`, userData);
      onUpdate(); // Benutzerliste aktualisieren
      onClose(); // Modal schließen
    } catch (error) {
      console.error('Fehler beim Setzen auf Inaktiv:', error.response?.data || error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const rolesArray = updatedUser.sr_rolle.map(role => ({
      id: role.id || undefined,
      rolle: role.rolle,
    }));

    const userData = { 
      id: updatedUser.id,
      username: updatedUser.username,
      email: updatedUser.email,
      confirmed: true,
      blocked: updatedUser.blocked || false,
      NLZ: updatedUser.NLZ || false,
      name: updatedUser.name,
      vorname: updatedUser.vorname,
      bezirk: updatedUser.bezirk ? { id: updatedUser.bezirk } : null,
      sr_rolle: rolesArray,
      ligatyp: updatedUser.ligatyp ? { id: updatedUser.ligatyp } : null,
    };

    try {
      console.log('Gesendete Nutzerdaten:', userData);
      await axiosInstance.put(`${process.env.REACT_APP_API_URL}/users/${updatedUser.id}`, userData);
      onUpdate(); // Benutzerliste aktualisieren
      onClose(); // Modal schließen
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Users:', error.response?.data || error.message);
    }
  };

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Benutzer bearbeiten</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label className="label">Benutzername</label>
            <input
              type="text"
              name="username"
              value={updatedUser.username}
              onChange={handleInputChange}
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">E-Mail</label>
            <input
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleInputChange}
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">Vorname</label>
            <input
              type="text"
              name="vorname"
              value={updatedUser.vorname}
              onChange={handleInputChange}
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label">Name</label>
            <input
              type="text"
              name="name"
              value={updatedUser.name}
              onChange={handleInputChange}
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label">Rollen</label>
            <Select
              isMulti
              options={roleOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={roleOptions.filter(role => updatedUser.sr_rolle.map(r => r.rolle).includes(role.value))}
              onChange={handleRoleChange}
            />
          </div>
          <div className="form-control mb-4">
            <label className="label">Bezirk</label>
            <Select
              options={districts}
              className="basic-single-select"
              classNamePrefix="select"
              value={districts.find(district => district.value === updatedUser.bezirk) || null}
              onChange={handleDistrictChange}
              isClearable
              placeholder="Wählen Sie einen Bezirk"
            />
          </div>
          <div className="form-control mb-4">
            <label className="label">Spielklasse (Ligatyp)</label>
            <Select
              options={ligatypOptions}
              className="basic-single-select"
              classNamePrefix="select"
              value={ligatypOptions.find(option => option.value === updatedUser.ligatyp) || null}
              onChange={handleLigatypChange}
              isClearable
              placeholder="Wählen Sie eine Spielklasse"
            />
          </div>
          <div className="modal-action">
            <button type="submit" className="btn btn-primary">
              Speichern
            </button>
            <button type="button" className="btn" onClick={onClose}>
              Abbrechen
            </button>
            <button type="button" className="btn btn-error" onClick={handleSetInactive}>
              Inaktiv setzen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
