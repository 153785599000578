import axiosInstance from "../components/axiosConfig";

export const getSpielDataById = async (spielId) => {
  try {
    // Anfrage an das Strapi-Backend (URL kann je nach deiner Strapi-Konfiguration variieren)
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/spiels/${spielId}?populate=*`);

    // Daten aus der verschachtelten Struktur extrahieren
    const spiel = response.data.data; // Hole die 'data'-Eigenschaft
    const attributes = spiel.attributes; // Hole die 'attributes' der Spiel-Daten

    // Extrahiere die relevanten Felder (Liga, Saison, Heimmannschaft, Gastmannschaft, Spieltag)
    const spielData = {
      id: spiel.id,
      liga: attributes.liga?.data?.attributes?.Name || 'Unbekannte Liga', // Achte auf Groß- und Kleinschreibung bei "Name"
      saison: attributes.saison?.data?.attributes?.titel || 'Unbekannte Saison', // Hier ist der Schlüssel "titel"
      heimmannschaft: attributes.heimverein?.data?.attributes?.name || 'Unbekannte Heimmannschaft',
      gastmannschaft: attributes.gastverein?.data?.attributes?.name || 'Unbekannte Gastmannschaft',
      spieltag: attributes.Spieltag || 'Unbekannter Spieltag',
      createdAt: attributes.createdAt,
      updatedAt: attributes.updatedAt,
    };

    // Debugging-Log (optional)
    console.log('Extrahierte Spiel-Daten:', spielData);

    return spielData; // Rückgabe der extrahierten Spiel-Daten
  } catch (error) {
    console.error('Fehler beim Abrufen der Spiel-Daten von Strapi:', error);
    throw error; // Fehler weitergeben, damit er in der Komponente verarbeitet werden kann
  }
};
