import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig';

const PublishedScenesStat = () => {
  const [totalPublishedScenes, setTotalPublishedScenes] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPublishedScenes = async () => {
      try {
        const response = await axiosInstance.get('/videoszenes?filters[arbeitsstatus][$eq]=published');
        setTotalPublishedScenes(response.data.meta.pagination.total);
      } catch (error) {
        console.error('Error fetching published scenes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPublishedScenes();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Ladeanzeige
  }

  return (
    <div className="stat">
      <div className="stat-figure text-primary">
        {/* Kamera Icon */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block h-8 w-8 stroke-current">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6h18M4 6v12h16V6M10 12h4M12 10v4" />
        </svg>
      </div>
      <div className="stat-title">Veröffentliche Szenen</div>
      <div className="stat-value text-primary">{totalPublishedScenes}</div>
      <div className="stat-desc">Anzahl der veröffentlichten Szenen</div>
    </div>
  );
};

export default PublishedScenesStat;
