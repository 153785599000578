// src/redux/videoSceneSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const videoSceneSlice = createSlice({
  name: 'videoScene',
  initialState: {
    showForm: false,
  },
  reducers: {
    toggleForm: (state) => {
      state.showForm = !state.showForm;
    },
  },
});

export const { toggleForm } = videoSceneSlice.actions;

export default videoSceneSlice.reducer;

