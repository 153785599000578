import { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';

const useFetchData = () => {
  const [scenes, setScenes] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [clubs, setClubs] = useState([]);

  // Funktion zum Abrufen aller Szenen
  const fetchInitialScenes = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/videoszenes?filters[arbeitsstatus][$eq]=published&populate=*`);
      setScenes(response.data.data); // Setze alle Szenen
    } catch (error) {
      console.error('Fehler beim Laden der Videoszenen:', error);
      setScenes([]); // Leeres Array im Fehlerfall
    }
  };

  // Fetch Saisons, Ligen, Vereine (wird nur einmal beim ersten Rendern aufgerufen)
  const fetchFilterData = async () => {
    try {
      const [seasonsRes, leaguesRes, clubsRes] = await Promise.all([
        axiosInstance.get(`${process.env.REACT_APP_API_URL}/saisons`),
        axiosInstance.get(`${process.env.REACT_APP_API_URL}/ligas`),
        axiosInstance.get(`${process.env.REACT_APP_API_URL}/vereins`),
      ]);

      setSeasons(seasonsRes.data.data);
      setLeagues(leaguesRes.data.data);
      setClubs(clubsRes.data.data);
    } catch (error) {
      console.error('Fehler beim Laden der Filterdaten:', error);
    }
  };

  // Initialer Datenabruf für Szenen und Filterdaten
  useEffect(() => {
    fetchInitialScenes();  // Szenen beim ersten Laden abrufen
    fetchFilterData();      // Filterdaten laden
  }, []); // Läuft nur einmal beim ersten Rendern

  return { scenes, seasons, leagues, clubs };
};

export default useFetchData;
