import { createSlice } from '@reduxjs/toolkit';

const initialView = localStorage.getItem('homeView') || 'Home';

export const viewSlice = createSlice({
  name: 'view',
  initialState: {
    currentView: initialView,
  },
  reducers: {
    setView: (state, action) => {
      state.currentView = action.payload;
      localStorage.setItem('homeView', action.payload);
    },
  },
});

export const { setView } = viewSlice.actions;

export default viewSlice.reducer;



