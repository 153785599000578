export const spielstrafeOptions = [
    { value: 'direkter Freistoß', label: 'Direkter Freistoß' },
    { value: 'indirekter Freistoß', label: 'Indirekter Freistoß' },
    { value: 'SR-Ball', label: 'SR-Ball' },
    { value: 'Weiterspielen', label: 'Weiterspielen' },
    { value: 'Strafstoß', label: 'Strafstoß' },
  ];
  
  export const persoenlicheStrafeOptions = [
    { value: 'Keine persönliche Strafe', label: 'Keine persönliche Strafe' },
    { value: 'Verwarnung', label: 'Verwarnung' },
    { value: 'Feldverweis auf Dauer', label: 'Feldverweis auf Dauer' },
    { value: 'Feldverweis auf Zeit', label: 'Feldverweis auf Zeit' },
    { value: 'Gelb/Rot', label: 'Gelb/Rot' },
  ];
  
  export const quelleOptions = [
    { value: 'Die Ligen', label: 'Die Ligen' },
    { value: 'Sporttotal', label: 'Sporttotal' },
    { value: 'ARD', label: 'ARD' },
    { value: 'BR', label: 'BR' },
    { value: 'BFV.TV', label: 'BFV.TV'}
  ];
  