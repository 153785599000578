import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import AuthProvider from './components/AuthProvider/AuthProvider';
import Home from './components/Home';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import VideoszeneAnsicht from './components/VideoszeneAnsicht';
import AktuelleSzenen from './components/AktuelleSzenen';
import AlleSzenen from './components/AlleSzenen';
import Footer from './components/footer';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/aktuelle-szenen" element={<AktuelleSzenen />} />
            <Route path="/videoszene/:id" element={<VideoszeneAnsicht />} />
            <Route path="/alle-szenen" element={<AlleSzenen />} />
          </Routes>
          <Footer /> {/* Footer hier platzieren */}
        </AuthProvider>
      </Router>
    </Provider>
  );
};

export default App;
