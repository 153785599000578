import React from 'react';

const SearchBar = ({ searchText, setSearchText }) => {
  return (
    <div className="form-control mb-4 justify-center items-center">
      <div className="input-group flex w-full md:w-2/3 lg:w-1/2 mx-auto">
        <input
          type="text"
          placeholder="Suchen..."
          className="input input-bordered flex-grow"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button className="btn btn-ghost" onClick={() => setSearchText('')}>
          ✕
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
