import React from 'react';

const SpielDetails = ({ spielData }) => {
  if (!spielData) {
    return <p>Keine Spieldaten verfügbar.</p>;
  }

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Spiel Details</h3>
      <p><strong>Heimmannschaft:</strong> {spielData.heimmannschaft || 'Nicht festgelegt'}</p>
      <p><strong>Gastmannschaft:</strong> {spielData.gastmannschaft || 'Nicht festgelegt'}</p>
      <p><strong>Liga:</strong> {spielData.liga || 'Nicht festgelegt'}</p>
      <p><strong>Saison:</strong> {spielData.saison || 'Nicht festgelegt'}</p>
      <p><strong>Spieltag:</strong> {spielData.spieltag || 'Nicht festgelegt'}</p>
    </div>
  );
};

export default SpielDetails;
