import axios from 'axios';

// Token aus dem localStorage abrufen
const getToken = () => localStorage.getItem('authToken');

// Axios-Instanz erstellen
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,  // Basis-URL für alle Anfragen
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000, // Timeout in Millisekunden
});

// Request Interceptor: Token hinzufügen, wenn vorhanden
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Token zum Authorization-Header hinzufügen
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Globale Fehlerbehandlung mit Interceptors
axiosInstance.interceptors.response.use(
  (response) => response, // Erfolgreiche Antwort einfach durchlassen
  (error) => {
    // Globale Fehlerbehandlung
    if (error.response) {
      if (error.response.status === 403) {
        console.error('Zugriff verweigert. Status 403');
        // Optional: Hier könntest du eine Umleitung zur Login-Seite hinzufügen
      } else if (error.response.status === 401) {
        console.error('Nicht autorisiert. Status 401');
        // Token ungültig oder abgelaufen, eventuell Logout durchführen
        // Optional: User ausloggen oder Token entfernen
      } else {
        console.error(`Fehler: ${error.response.statusText}`);
      }
    } else {
      console.error('Anfrage fehlgeschlagen:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
