// helpers.js

export const AUTH_TOKEN = 'auth_token_key'; 
export const getToken = () => localStorage.getItem(AUTH_TOKEN);


export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => localStorage.removeItem(AUTH_TOKEN);

export const generateThumbnailWithCanvas = (videoFile) => {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    if (!videoFile) {
      return reject(new Error('Kein Video übergeben.'));
    }

    video.src = URL.createObjectURL(videoFile);

    // Fehlerabfangen, wenn das Video nicht geladen werden kann
    video.onerror = (err) => {
      reject(new Error('Fehler beim Laden des Videos: ' + err.message));
    };

    video.onloadeddata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Stelle sicher, dass die Mitte des Videos erreicht wird
      video.currentTime = video.duration / 2;

      video.onseeked = () => {
        try {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            if (!blob) {
              return reject(new Error('Fehler beim Erstellen des Thumbnails.'));
            }
            const thumbnailFile = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
            resolve(thumbnailFile);
          }, 'image/jpeg');
        } catch (err) {
          reject(new Error('Fehler beim Erstellen des Thumbnails: ' + err.message));
        }
      };
    };
  });
};
