import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import RelatedScenesSidebar from './RelatedScenesSidebar'; // Importiere die Sidebar-Komponente

const VideoszeneModal = ({ id }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Für das Ermitteln der vorherigen Seite
  const [scene, setScene] = useState(null);
  const [gameData, setGameData] = useState(null); // Neuer State für die Spieldaten
  const [signedVideoUrl, setSignedVideoUrl] = useState(null); // State für die signierte Video-URL
  const videoRef = useRef(null); // Referenz für den Video-Player

  useEffect(() => {
    const fetchScene = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/videoszenes/${id}?populate=spiel.heimverein,spiel.gastverein,spiel.liga,Video,thumbnail,Bewertung`, 
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
        );
        const sceneData = response.data.data;
        setScene(sceneData);

        // Extrahiere die Spieldaten, falls verfügbar
        const spielData = sceneData.attributes.spiel?.data;
        if (spielData) {
          setGameData(spielData.attributes);
        }

        // Wenn ein Video vorhanden ist, hole die signierte Video-URL
        if (sceneData.attributes.Video?.data) {
          const videoHash = sceneData.attributes.Video.data.attributes.hash;
          const videoExt = sceneData.attributes.Video.data.attributes.ext;
          const videoKey = videoHash + videoExt;

          const signedVideoRes = await axiosInstance.get(
            `${process.env.REACT_APP_API_URL}/get-signed-video-url/${videoKey}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              },
            }
          );

          setSignedVideoUrl(signedVideoRes.data.signedUrl); // Setze die signierte Video-URL
        }

      } catch (error) {
        console.error('Fehler beim Laden der Szene:', error);
      }
    };

    fetchScene();
  }, [id]);

  // Funktion für das Rendern der Bewertungs-Radio-Buttons
  const renderRatingRadioButtons = (bewertung) => (
    <div className="flex items-center space-x-2">
      {[1, 2, 3, 4, 5, 6].map((value) => (
        <label key={value} className="flex items-center space-x-1">
          <input
            type="radio"
            name={`bewertung-${bewertung.id}`}
            value={value}
            checked={bewertung.Bewertung === value}
            className="radio radio-primary"
            readOnly
          />
          <span>{value}</span>
        </label>
      ))}
    </div>
  );

  if (!scene) {
    return <p className="text-center">Lade Szene...</p>;
  }

  const {
    titel,
    beschreibung,
    download,
    spielstrafe,
    persoenlicheStrafe,
    Video,
    Bewertung,
  } = scene.attributes;

   // Trennen der Bewertungen in verschiedene Sektionen
   const srBewertungen = Bewertung.filter(bewertung => !bewertung.Kategorie.startsWith('SRA'));
   const sra1Bewertungen = Bewertung.filter(bewertung => bewertung.Kategorie.startsWith('SRA1'));
   const sra2Bewertungen = Bewertung.filter(bewertung => bewertung.Kategorie.startsWith('SRA2'));


  return (
    <div className="w-11/12 mx-auto p-6 grid grid-cols-1 gap-4">
      {/* Linke Spalte - Details der aktuellen Szene (nimmt 80% ein) */}
      <div>
    

        {/* Titel der Szene */}
        <h2 className="text-3xl font-bold mb-4">{titel}</h2>

        {/* Spieldaten anzeigen */}
        {gameData && (
          <div className="mb-4">
            <p className="text-lg font-semibold">
              {gameData.heimverein?.data?.attributes?.name || 'Heimmannschaft'} - {gameData.gastverein?.data?.attributes?.name || 'Gastmannschaft'}
            </p>
            <p className="text-sm text-gray-600">
              {gameData.liga?.data?.attributes?.Name || 'Liga'}, {gameData.Spieltag}. Spieltag
            </p>
          </div>
        )}

        {/* Video-Player */}
        {signedVideoUrl ? (
          <div className="flex justify-center mb-4">
            <video
              controls
              className="w-9/12 rounded-lg shadow-lg"
              controlsList="nodownload" // Verhindert das Herunterladen über den UI-Button
              onContextMenu={(e) => e.preventDefault()} // Deaktiviert den Rechtsklick
              ref={videoRef}
            >
              <source src={signedVideoUrl} type={Video?.data?.attributes?.mime} />
              Dein Browser unterstützt das Video-Tag nicht.
            </video>
          </div>
        ) : (
          <p>Kein Video verfügbar.</p>
        )}

        {/* Beschreibung der Szene */}
        <div className="mb-6">
          <h3 className="text-2xl font-semibold">Beschreibung</h3>
          <p className="text-justify prose">{beschreibung || 'Keine Beschreibung verfügbar.'}</p>
        </div>

        {/* Spielstrafe und Persönliche Strafe */}
        <div className="mb-6">
          <h3 className="text-2xl font-semibold">Entscheidung</h3>
          <p className="text-sm">Spielstrafe: {spielstrafe || 'Keine Angabe'}</p>
          <p className="text-sm">Persönliche Strafe: {persoenlicheStrafe || 'Keine Angabe'}</p>
        </div>

        {/* Bewertungen */}
        <div className="mb-6">
          <h3 className="text-2xl font-bold mb-4">Bewertungen</h3>
          {/* Sektion für Schiedsrichter */}
          <div className="card shadow-lg p-4">
            <h4 className="text-xl font-semibold mb-2">Schiedsrichter</h4>
            {srBewertungen.length > 0 ? (
              srBewertungen.map(bewertung => (
                <div key={bewertung.id} className="mb-4">
                  <p>{bewertung.Kategorie}</p>
                  {renderRatingRadioButtons(bewertung)}
                </div>
              ))
            ) : (
              <p className="text-sm">Keine Bewertungen vorhanden.</p>
            )}
          </div>

          {/* Sektion für Schiedsrichter-Assistent 1 */}
          <div className="card shadow-lg p-4">
            <h4 className="text-xl font-semibold mb-2">Schiedsrichter-Assistent 1</h4>
            {sra1Bewertungen.length > 0 ? (
              sra1Bewertungen.map(bewertung => (
                <div key={bewertung.id} className="mb-4">
                  <p>{bewertung.Kategorie}</p>
                  {renderRatingRadioButtons(bewertung)}
                </div>
              ))
            ) : (
              <p className="text-sm">Keine Bewertungen vorhanden.</p>
            )}
          </div>

          {/* Sektion für Schiedsrichter-Assistent 2 */}
          <div className="card shadow-lg p-4">
            <h4 className="text-xl font-semibold mb-2">Schiedsrichter-Assistent 2</h4>
            {sra2Bewertungen.length > 0 ? (
              sra2Bewertungen.map(bewertung => (
                <div key={bewertung.id} className="mb-4">
                  <p>{bewertung.Kategorie}</p>
                  {renderRatingRadioButtons(bewertung)}
                </div>
              ))
            ) : (
              <p className="text-sm">Keine Bewertungen vorhanden.</p>
            )}
          </div>
        </div>
      </div>
      </div>
  );
};

export default VideoszeneModal;
