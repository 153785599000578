import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  
import { useAuthContext } from './AuthProvider/AuthProvider';
import { HiMenu } from 'react-icons/hi';  // Importiere das Hamburger Icon

const Navbar = () => {
  const { user, logout } = useAuthContext();
  const navigate = useNavigate();  
  const [isMenuOpen, setMenuOpen] = useState(false);  

  // Funktion, um zu einer bestimmten Route zu navigieren
  const handleClick = (path) => {
    navigate(path);  
    setMenuOpen(false);  
  };

  // Funktion für Login/Logout
  const handleAuthClick = () => {
    if (user) {
      logout();  
      navigate('/');  
    } else {
      navigate('/login');  
    }
    setMenuOpen(false);  
  };

  // Funktion zum Schließen des Menüs, wenn außerhalb des Menüs geklickt wird
  const handleOutsideClick = (e) => {
    // Wenn der Benutzer neben dem Menü klickt, wird es geschlossen
    if (e.target.id === 'menu-overlay') {
      setMenuOpen(false);
    }
  };

  return (
    <div className="flex justify-center mt-10 z-50">
      <div className="navbar bg-primary text-white rounded-lg shadow-lg p-4 mb-5 w-11/12 flex justify-between">
        
        {/* Hamburgermenü für mobile Ansicht */}
        <div className="navbar-start w-1/3 lg:w-auto flex justify-start lg:hidden">
          <button 
            className="btn btn-ghost p-0" 
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <HiMenu size={30} />  {/* React Icon für das Hamburgermenü */}
          </button>
        </div>
        
        {/* Logo für mobile Ansicht in der Mitte und für größere Bildschirme links */}
        <div className="navbar-center w-1/3 lg:w-auto flex justify-center lg:justify-start">
          <button onClick={() => handleClick('/')} className="btn btn-ghost p-0">
            <img src="/Logo-weiss.png" alt="Logo" className="h-8" />
          </button>
        </div>
        
        {/* Menü für größere Bildschirme */}
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            {user && (
              <>
                <li>
                  <button onClick={() => handleClick('/')}>Home</button>
                </li>
                <li>
                  <button onClick={() => handleClick('/aktuelle-szenen')}>Aktuelle Szenen</button>
                </li>
                <li>
                  <button onClick={() => handleClick('/alle-szenen')}>Alle Szenen</button>
                </li>
                <li>
                  <button onClick={() => handleClick('/dashboard')}>Verwaltung</button>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* Login/Logout Button nur auf größeren Bildschirmen */}
        <div className="navbar-end w-1/3 lg:w-auto hidden lg:flex justify-end">
          <button className="btn" onClick={handleAuthClick}>
            {user ? 'Logout' : 'Login'}
          </button>
        </div>
      </div>

      {/* Overlay zum Schließen des Menüs, wenn daneben geklickt wird */}
      {isMenuOpen && (
        <div
          id="menu-overlay"
          className="fixed inset-0 z-30 bg-black bg-opacity-50 flex justify-center items-start"
          onClick={handleOutsideClick}
        >
          <div className="bg-primary text-white w-11/12 max-w-md mt-16 shadow-lg rounded-lg z-40">
            <ul className="menu p-4 w-full text-center">
              {user && (
                <>
                  <li className="py-2">
                    <button onClick={() => handleClick('/')}>Home</button>
                  </li>
                  <li className="py-2">
                    <button onClick={() => handleClick('/aktuelle-szenen')}>Aktuelle Szenen</button>
                  </li>
                  <li className="py-2">
                    <button onClick={() => handleClick('/alle-szenen')}>Alle Szenen</button>
                  </li>
                  <li className="py-2">
                    <button onClick={() => handleClick('/dashboard')}>Verwaltung</button>
                  </li>
                  <li className="py-2">
                    <button onClick={handleAuthClick}>
                      {user ? 'Logout' : 'Login'}
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
