import { createSlice } from '@reduxjs/toolkit';

// Der initiale State des Users
const initialState = {
  user: null, // Der Benutzer wird nach dem Abrufen hier gespeichert
};

// Erstelle einen Redux-Slice für den Benutzer
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      // Speichere die Benutzerinformationen im State
      state.user = action.payload;
    },
    clearUser: (state) => {
      // Entferne die Benutzerinformationen (z.B. beim Logout)
      state.user = null;
    },
  },
});

// Exportiere die Actions, die du verwenden kannst
export const { setUser, clearUser } = userSlice.actions;

// Exportiere den Reducer, damit er in den Store integriert werden kann
export default userSlice.reducer;
