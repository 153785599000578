import React from 'react';
import ViewsLast30Days from './ViewsLast30Days'; // Komponente für die letzten 30 Tage
import MostViewedScenes from './MostViewedScenes'; // Komponente für die meistgesehenen Szenen
import UnconfirmedUsersFromDistrict from './UnconfirmedUsersFromDistrict';
import PublishedScenesStat from './PublishedSceneStat'; // Komponente für veröffentlichte Szenen
import UserCountStat from './UserCountStat'; // Komponente für Benutzeranzahl
import TotalViewsStat from './TotalViewsStat'; // Komponente für Gesamtanzahl der Views

const Statistiken = () => (
  <div className="container mx-auto p-4">
    <h1 className="text-2xl font-bold mb-4">Statistiken</h1>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <PublishedScenesStat /> {/* Komponente für veröffentlichte Szenen */}
      <UserCountStat /> {/* Komponente für Benutzeranzahl */}
      <TotalViewsStat /> {/* Komponente für Gesamtanzahl der Views */}
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      <ViewsLast30Days /> {/* Komponente für die letzten 30 Tage */}
      <MostViewedScenes /> {/* Komponente für die meistgesehenen Szenen */}
    </div>
    <div className="grid grid-cols-1 gap-4 mt-4">
      <UnconfirmedUsersFromDistrict /> {/* Komponente für nicht bestätigte Benutzer */}
    </div>
  </div>
);

export default Statistiken;

