import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';

const ReleaseSearchBar = ({ onSearch }) => {
  const [searchMode, setSearchMode] = useState('scene'); // 'scene' oder 'game'
  const [searchText, setSearchText] = useState('');
  const [selectedThema, setSelectedThema] = useState('');
  const [selectedBewertung, setSelectedBewertung] = useState('');
  const [team, setTeam] = useState('');
  const [liga, setLiga] = useState('');
  const [saison, setSaison] = useState('');
  const [themenList, setThemenList] = useState([]);

  // Themenliste aus der API laden
  useEffect(() => {
    const fetchThemen = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/themas`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
        );
        setThemenList(response.data.data);
      } catch (error) {
        console.error('Fehler beim Laden der Themen:', error);
      }
    };
    fetchThemen();
  }, []);

  const handleSearch = () => {
    onSearch({
      type: searchMode,
      searchText,
      thema: selectedThema,
      bewertung: selectedBewertung,
      team,
      liga,
      saison,
    });
  };

  return (
    <div className="w-full mb-4 p-4 bg-base-200 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <div className="tabs">
          <a
            className={`tab ${searchMode === 'scene' ? 'tab-active' : ''}`}
            onClick={() => setSearchMode('scene')}
          >
            Suche nach Szenen
          </a>
          <a
            className={`tab ${searchMode === 'game' ? 'tab-active' : ''}`}
            onClick={() => setSearchMode('game')}
          >
            Suche nach Spieldaten
          </a>
        </div>
      </div>

      {searchMode === 'scene' ? (
        <div className="flex flex-wrap gap-4">
          <input
            type="text"
            placeholder="Titel oder Beschreibung suchen..."
            className="input input-bordered w-full md:w-1/3"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <select
            className="select select-bordered w-full md:w-1/4"
            value={selectedThema}
            onChange={(e) => setSelectedThema(e.target.value)}
          >
            <option value="">Thema wählen</option>
            {themenList.map((thema) => (
              <option key={thema.id} value={thema.attributes.name}>
                {thema.attributes.name}
              </option>
            ))}
          </select>
          <select
            className="select select-bordered w-full md:w-1/4"
            value={selectedBewertung}
            onChange={(e) => setSelectedBewertung(e.target.value)}
          >
            <option value="">Bewertung wählen</option>
            {[1, 2, 3, 4, 5, 6].map((bewertung) => (
              <option key={bewertung} value={bewertung}>
                {bewertung}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          <input
            type="text"
            placeholder="Mannschaft"
            className="input input-bordered w-full md:w-1/3"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
          />
          <input
            type="text"
            placeholder="Liga"
            className="input input-bordered w-full md:w-1/4"
            value={liga}
            onChange={(e) => setLiga(e.target.value)}
          />
          <input
            type="text"
            placeholder="Saison"
            className="input input-bordered w-full md:w-1/4"
            value={saison}
            onChange={(e) => setSaison(e.target.value)}
          />
        </div>
      )}

      <div className="mt-4">
        <button className="btn btn-primary" onClick={handleSearch}>
          Suchen
        </button>
      </div>
    </div>
  );
};

export default ReleaseSearchBar;
