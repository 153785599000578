import React from 'react';

const ReleaseForm = ({ name, beschreibung, status, onNameChange, onBeschreibungChange, onStatusChange }) => {
  return (
    <>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Name</label>
        <input
          type="text"
          className="input input-bordered w-full"
          value={name}
          onChange={(e) => onNameChange(e.target.value)} // Sofortiges Speichern bei Änderung
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Beschreibung</label>
        <textarea
          className="textarea textarea-bordered w-full"
          value={beschreibung}
          onChange={(e) => onBeschreibungChange(e.target.value)} // Sofortiges Speichern bei Änderung
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Status</label>
        <select
          className="select select-bordered w-full"
          value={status}
          onChange={(e) => onStatusChange(e.target.value)} // Status kann auch gespeichert werden
        >
          <option value="Geplant">Geplant</option>
          <option value="Veröffentlicht">Veröffentlicht</option>
        </select>
      </div>
    </>
  );
};

export default ReleaseForm;
