import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axiosInstance from './axiosConfig';
import Select from 'react-select';
import EditUserModal from './EditUserModal';
import NewUserModal from './NewUserModal';
import { useSelector } from 'react-redux';
import UserTable from './UserTable';
import emailjs from 'emailjs-com';

const Userdaten = () => {
  const [users, setUsers] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [activeTab, setActiveTab] = useState('Alle');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [excelFile, setExcelFile] = useState(null); // Zustand für Excel-Datei

  const currentUser = useSelector((state) => state.user?.user);
  const tabs = ['Alle', 'Schiedsrichter', 'Coaches', 'Funktionäre', 'Sonstige'];
  const higherPlayClasses = ['Bundesliga', '2. Bundesliga', '3. Liga', 'Regionalliga', 'Bayernliga', 'Landesliga'];
  const roleOrder = ['VSA', 'KT', 'BSA', 'GLW', 'GSO', 'GSA'];

  const hasRole = (role) => currentUser?.sr_rolle?.some((r) => r.rolle === role);

  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };

  const generatePassword = () => {
    return Math.random().toString(36).slice(-8);
  };

  const fetchLigaTypId = async (ligaName) => {
    try {
      const response = await axiosInstance.get(`/ligatyps?filters[Name][$eq]=${ligaName}`);
      return response.data?.data[0]?.id || null;
    } catch (error) {
      console.error('Fehler beim Abrufen der Ligatyp-ID:', error);
      return null;
    }
  };

  // Optionen für die Rollen
  const roleOptions = [
    //{ value: 'VSA', label: 'VSA' },
    { value: 'BSA', label: 'BSA' },
    { value: 'BEO', label: 'BEO' },
    { value: 'SR', label: 'SR' },
    { value: 'LW', label: 'LW' },
    { value: 'GSO', label: 'GSO' },
    { value: 'GSA', label: 'GSA' },
    //{ value: 'KT', label: 'KT' }
  ];


// E-Mail-Benachrichtigung senden
const sendConfirmationEmail = (email, username, password, vorname) => {
 const templateParams = {
    username: username,
    password: password,
    vorname: vorname,
    homepage_url: process.env.REACT_APP_HOMEPAGE_URL,
    user_email: email
  };

  console.log('Template Parameters:', templateParams);
  
  emailjs.send(
    process.env.REACT_APP_EMAILJS_SERVICE_ID,
    process.env.REACT_APP_EMAILJS_TEMPLATE_ID_REG,
    templateParams,
    process.env.REACT_APP_EMAILJS_USER_ID
  )
  .then((response) => {
    console.log('Email successfully sent!', response.status, response.text);
  })
  .catch((err) => {
    console.error('Error sending email:', err);
  });
};

const handleMassUpload = async () => {
  if (!excelFile) {
    alert("Bitte laden Sie eine Excel-Datei hoch.");
    return;
  }

  try {
    const fileData = await excelFile.arrayBuffer();
    const workbook = XLSX.read(fileData, { type: 'array' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 0 });

    const getDistrictId = (districtName) => {
      const district = districts.find(d => d.label === districtName);
      return district ? district.value : null;
    };

    for (const newUser of data) {
      const password = generatePassword();
      let baseUsername = `${newUser.vorname}_${newUser.name}`.toLowerCase().replace(/\s+/g, '_');
      let uniqueUsername = baseUsername;
      let counter = 1;
      let isUnique = false;

      while (!isUnique) {
        const response = await axiosInstance.get(`/users?filters[username][$eq]=${uniqueUsername}`);
        if (response.data.length === 0) {
          isUnique = true;
        } else {
          uniqueUsername = `${baseUsername}_${counter}`;
          counter++;
        }
      }

      const ligaTypId = await fetchLigaTypId(newUser.ligatyp);
      if (!ligaTypId) {
        console.error(`Ligatyp "${newUser.ligatyp}" nicht gefunden.`);
        continue;
      }

      const bezirkId = getDistrictId(newUser.bezirk);
      if (!bezirkId) {
        console.error(`Bezirk "${newUser.bezirk}" nicht gefunden.`);
        continue;
      }

      const isNLZ = newUser.NLZ.toLowerCase() === 'true';
      const roles = newUser.sr_rolle.split(',').map(role => role.trim());
      const srRolleArray = roles.map(role => ({ rolle: role }));

      const userData = {
        username: uniqueUsername,
        email: newUser.email,
        password,
        confirmed: false,
        blocked: false,
        role: '1',
        NLZ: isNLZ,
        name: newUser.name,
        vorname: newUser.vorname,
        bezirk: bezirkId,
        sr_rolle: srRolleArray,
        ligatyp: ligaTypId
      };

      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/users`, userData);

      // Nach erfolgreicher Benutzeranlage eine Bestätigungs-E-Mail senden
      sendConfirmationEmail(newUser.email, uniqueUsername, password, newUser.vorname);
    }

    setSuccessMessage("Alle Benutzer wurden erfolgreich hochgeladen und benachrichtigt.");
    fetchUsers();
  } catch (error) {
    console.error('Fehler beim Hochladen der Benutzer:', error);
    alert('Fehler beim Hochladen der Benutzer. Bitte überprüfen Sie die Daten und versuchen Sie es erneut.');
  }
};


  
    

  const fetchUsers = async () => {
    try {
      let response;
      if (hasRole('BSA')) {
        const userDistrict = currentUser?.bezirk?.id;
        if (userDistrict) {
          response = await axiosInstance.get(`/users?filters[bezirk][id][$eq]=${userDistrict}&filters[blocked][$eq]=false&populate=*`);
          const nonHigherPlayClassUsers = response.data.filter(user => 
            !higherPlayClasses.includes(user.ligatyp?.Name)
          );
          setUserCount(nonHigherPlayClassUsers.length);
        } else {
          console.error('Bezirk nicht gefunden für BSA-Benutzer');
          return;
        }
      } else {
        response = await axiosInstance.get('/users?filters[blocked][$eq]=false&populate=*');
      }
      setUsers(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der User:', error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axiosInstance.get('/bezirks');
      setDistricts(response.data.data.map(district => ({
        value: district.id,
        label: district.attributes.name,
      })));
    } catch (error) {
      console.error('Fehler beim Abrufen der Bezirke:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchDistricts();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const sortByRoleOrder = (usersList) => {
    return usersList.sort((a, b) => {
      const aRole = a.sr_rolle.find(role => roleOrder.includes(role.rolle))?.rolle || '';
      const bRole = b.sr_rolle.find(role => roleOrder.includes(role.rolle))?.rolle || '';
      return roleOrder.indexOf(aRole) - roleOrder.indexOf(bRole);
    });
  };

  const sortByPlayClass = (usersList) => {
    return usersList.sort((a, b) => {
      const aClass = higherPlayClasses.indexOf(a.ligatyp?.Name || '');
      const bClass = higherPlayClasses.indexOf(b.ligatyp?.Name || '');
      return aClass - bClass;
    });
  };

  const filteredUsers = users.filter((user) => {
    const userDistrictId = user.bezirk?.id;
    const currentUserDistrictId = currentUser?.bezirk?.id;

    if (!currentUser) return false;
  
    if (hasRole('BSA')) {
      if (activeTab === 'Alle') {
        return userDistrictId === currentUserDistrictId;
      }
      if (activeTab === 'Schiedsrichter') {
        return user.sr_rolle.some(role => role.rolle === 'SR') && userDistrictId === currentUserDistrictId;
      }
      if (activeTab === 'Coaches') {
        return user.sr_rolle.some(role => role.rolle === 'BEO') && userDistrictId === currentUserDistrictId;
      }
      if (activeTab === 'Funktionäre') {
        const validRoles = ['VSA', 'KT', 'BSA', 'GLW', 'GSO', 'GSA'];
        return user.sr_rolle.some(role => validRoles.includes(role.rolle)) && userDistrictId === currentUserDistrictId;
      }
    }

    if (hasRole('VSA')) {
      if (activeTab === 'Alle') return true;
      if (activeTab === 'Schiedsrichter') {
        return user.sr_rolle.some(role => role.rolle === 'SR');
      }
      if (activeTab === 'Coaches') {
        return user.sr_rolle.some(role => role.rolle === 'BEO');
      }
      if (activeTab === 'Funktionäre') {
        const validRoles = ['VSA', 'KT', 'BSA', 'GLW', 'GSO', 'GSA'];
        return user.sr_rolle.some(role => validRoles.includes(role.rolle));
      }
    }

    return false;
  });

  const sortedUsers = activeTab === 'Funktionäre' ? sortByRoleOrder(filteredUsers) : sortByPlayClass(filteredUsers);

  const progressPercentage = Math.min((userCount / 100) * 100, 100);

  if (!hasRole('KT') && !hasRole('VSA') && !hasRole('BSA')) {
    return <div>Sie haben keine Berechtigung, auf diese Seite zuzugreifen.</div>;
  }

  return (
    <div className="w-full p-4 max-w-4xl mx-auto">
      {successMessage && <div className="alert alert-success">{successMessage}</div>}

      {hasRole('BSA') && (
        <div className="mb-4">
          <div className="text-lg mb-2">Aktive Benutzer im Bezirk ohne höhere Qualifikation: {userCount} von 100</div>
          <progress className="progress progress-primary w-full" value={progressPercentage} max="100"></progress>
        </div>
      )}

      {progressPercentage < 100 && (
        <button className="btn btn-primary mb-4 w-full sm:w-auto" onClick={toggleModal}>
          Neuen User anlegen
        </button>
      )}

      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} className="file-input w-full max-w-xs ml-4" />
      <button className="btn btn-secondary mb-4 w-full sm:w-auto" onClick={handleMassUpload}>
        Massen-Upload starten
      </button>

      <div role="tablist" className="tabs tabs-boxed mb-4 overflow-x-auto">
        {tabs.map((tab) => (
          <a
            key={tab}
            role="tab"
            className={`tab ${activeTab === tab ? 'tab-active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </a>
        ))}
      </div>

      {activeTab === 'Alle' && <UserTable users={sortedUsers} handleEditUser={handleEditUser} />}

      <NewUserModal 
        isOpen={isModalOpen} 
        onClose={toggleModal} 
        fetchUsers={fetchUsers} 
        districts={districts} 
        setSuccessMessage={setSuccessMessage}
      />

      {isEditModalOpen && selectedUser && (
        <EditUserModal
          user={selectedUser}
          districts={districts}
          onClose={() => {
            toggleEditModal();
            setSelectedUser(null);
          }}
          onUpdate={fetchUsers}
        />
      )}
    </div>
  );
};

export default Userdaten;
