import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosConfig';

const SzeneKarte = ({ scene, releaseId, from }) => {
  const navigate = useNavigate();
  const [signedThumbnailUrl, setSignedThumbnailUrl] = useState(null);
  const [isSeen, setIsSeen] = useState(false);

  const decodeToken = (token) => {
    if (!token) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Fehler beim Decodieren des Tokens:', error);
      return null;
    }
  };

  const authToken = localStorage.getItem('authToken');
  const decodedToken = decodeToken(authToken);
  const userId = decodedToken ? decodedToken.id : null;

  const checkIfSceneSeen = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/user-szenens?filters[user][id][$eq]=${userId}&filters[videoszene][id][$eq]=${scene.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.data.length > 0) {
        setIsSeen(true);
      }
    } catch (error) {
      console.error('Fehler beim Überprüfen des Gesehen-Status:', error);
    }
  };

  useEffect(() => {
    const fetchSignedThumbnailUrl = async () => {
      if (scene.attributes.thumbnail?.data) {
        try {
          const thumbnailKey = scene.attributes.thumbnail.data.attributes.hash + scene.attributes.thumbnail.data.attributes.ext;
          const signedThumbnailRes = await axiosInstance.get(
            `${process.env.REACT_APP_API_URL}/get-signed-thumbnail-url/${thumbnailKey}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              },
            }
          );
          setSignedThumbnailUrl(signedThumbnailRes.data.signedUrl);
        } catch (err) {
          console.error('Fehler beim Laden der signierten Thumbnail-URL:', err);
        }
      }
    };

    fetchSignedThumbnailUrl();
    checkIfSceneSeen();
  }, [scene, userId]);

  const handleSceneClick = () => {
    navigate(`/videoszene/${scene.id}`, { state: { from, releaseId } });
  };

  return (
    <div
      className="card shadow-md p-4 border cursor-pointer relative"
      onClick={handleSceneClick}
    >
      {/* Thumbnail */}
      <div className="w-full relative pb-[56.25%] mb-4">
        <img
          src={signedThumbnailUrl || 'https://via.placeholder.com/150'}  // Verwende die signierte URL oder ein Platzhalterbild
          alt={scene.attributes.titel}
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
      </div>

      {/* Titel mit "Gesehen"-Tag */}
      <h4 className="font-bold text-lg mb-2 flex justify-between items-center">
        <span className="truncate max-w-[80%]">{scene.attributes.titel}</span>
        {isSeen && (
          <span className="badge badge-success ml-2 flex-shrink-0">
            Gesehen
          </span>
        )}
      </h4>

      {/* Beschreibung (50 Zeichen) */}
      <p className="text-sm mb-2">
        {scene.attributes.beschreibung
          ? scene.attributes.beschreibung.substring(0, 100) + '...'
          : 'Keine Beschreibung'}
      </p>

      {/* Tags unten rechts */}
      <div className="absolute bottom-2 right-2 flex gap-2 flex-wrap justify-end">
        {scene.attributes.thema?.map((tag) => (
          <span key={tag.id} className="badge badge-primary badge-sm">
            {tag.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default SzeneKarte;
