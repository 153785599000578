export const kategorien = {
    schiedsrichter: [
      "Rubrik 1: Tore (anerkannt / aberkannt) + Regelverstöße",
      "Rubrik 1: Strafstöße (gegeben / nicht gegeben)",
      "Rubrik 1: Feldverweise mittels Roter Karte (ausgesprochen / nicht ausgesprochen)",
      "Erkennen des Spielcharakters / von Spielphasen",
      "Situationsorientiertes Vorgehen",
      "Antizipation / Prävention",
      "Vorteil",
      "Eingriffszeitpunkt / Verzögerter Pfiff",
      "Regelkonformität / Regelverstöße",
      "Fußvergehen",
      "Oberkörpervergehen",
      "Handspiel",
      "Simulation",
      "Spielfortsetzungen",
      "Ansprachen / Ermahnungen angemessen bzw. fehlend",
      "Verwarnungen angemessen bzw. fehlend",
      "Feldverweise mittels Gelb/Roter-Karte (Zeitstrafe) angemessen bzw. fehlend",
      "Feldverweise mittels Roter Karte angemessen bzw. fehlend",
      "Präsentation",
      "Akzeptanz / Persönlichkeit",
      "Berechenbarkeit",
      "Durchsetzungsvermögen",
      "Entscheidungssicherheit/-schnelligkeit",
      "Cleverness / Taktisches Verhalten",
      "Souveränität / Gelassenheit bei Kritik",
      "Kommunikation (Ansprache, Dialog)",
      "Präsentation (Gestik, Mimik, Pfiffe)",
      "Körperspannung / Distanz",
      "Ausdauer",
      "Sprint",
      "Athletik / Variabilität",
      "Einblick und Positionierung laufendes Spiel",
      "Einblick und Positionierung Spielfortsetzungen",
      "Spielnähe / Präsenz",
      "Zusammenarbeit Regel 12",
      "Zusammenarbeit Disziplinarkontrolle",
      "Zusammenarbeit Abseits",
      "Übereinstimmung Spielfortsetzungen",
      "Kommunikation"
    ],
    sra1: [
      "Abseits - Wahrnehmung",
      "Abseits - Regelauslegung",
      "Unterstützung Regel 12 + Torerzielung",
      "Unterstützung Disziplinarkontrolle",
      "Unterstützung Spielfortsetzungen",
      "Persönliches Auftreten",
      "Zeichengebung / Fahnentechnik",
      "Fitness / Stellungsspiel",
      "Umgang mit Spielern und Offiziellen",
      "Administrative Aufgaben"
    ],
    sra2: [
      "Abseits - Wahrnehmung",
      "Abseits - Regelauslegung",
      "Unterstützung Regel 12 + Torerzielung",
      "Unterstützung Disziplinarkontrolle",
      "Unterstützung Spielfortsetzungen",
      "Persönliches Auftreten",
      "Zeichengebung / Fahnentechnik",
      "Fitness / Stellungsspiel",
      "Umgang mit Spielern und Offiziellen",
      "Administrative Aufgaben"
    ]
  };