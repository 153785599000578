import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';

const PasswordResetPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const email = searchParams.get('email');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (!code) {
      message.error("Ungültiger oder fehlender Reset-Code.");
      navigate('/login');
    }
  }, [code, navigate]);

  const handleReset = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      message.error("Die Passwörter stimmen nicht überein.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code,
          password,
          passwordConfirmation: confirmPassword
        })
      });

      const data = await response.json();

      if (response.ok) {
        message.success("Passwort erfolgreich zurückgesetzt. Du kannst dich jetzt einloggen.");
        navigate('/login');
      } else {
        console.error("API-Fehler:", data);
        message.error(data?.error?.message || "Zurücksetzen fehlgeschlagen.");
      }
    } catch (error) {
      console.error("Netzwerkfehler beim Zurücksetzen:", error);
      message.error("Es ist ein Fehler aufgetreten.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-base-200">
      <div className="card w-full max-w-md shadow-lg bg-white p-8">
        <h2 className="text-2xl font-bold mb-4 text-center">Passwort zurücksetzen</h2>
        {email && <p className="text-sm text-center mb-4 text-gray-600">Für: {email}</p>}
        <form onSubmit={handleReset}>
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">Neues Passwort</span>
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control mb-6">
            <label className="label">
              <span className="label-text">Passwort bestätigen</span>
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="input input-bordered"
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-full" disabled={loading}>
            {loading ? "Wird gesendet..." : "Passwort zurücksetzen"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetPage;
