import React, { useState, useEffect } from 'react';
import { fetchLigen, fetchSaisons, fetchMannschaften, checkIfSpielExistiert, createSpiel } from './api/api'; // API-Funktionen
import SelectInput from './shared/SelectInput'; // Wiederverwendbare Input-Komponente für Dropdowns

const SpielHinzufuegen = ({ onSpielHinzufuegen, scene }) => {
  const [liga, setLiga] = useState('');
  const [ligen, setLigen] = useState([]);
  const [saison, setSaison] = useState('');
  const [saisons, setSaisons] = useState([]);
  const [heimmannschaft, setHeimmannschaft] = useState('');
  const [gastmannschaft, setGastmannschaft] = useState('');
  const [mannschaften, setMannschaften] = useState([]);
  const [spieltag, setSpieltag] = useState('');
  const [spielExistiert, setSpielExistiert] = useState(null);
  const [spielLaden, setSpielLaden] = useState(false);
  const [spielId, setSpielId] = useState(null);

  // Lade Ligen
  useEffect(() => {
    const loadLigen = async () => {
      const data = await fetchLigen();
      setLigen(data);
    };
    loadLigen();
  }, []);

  // Lade Saisons
  useEffect(() => {
    const loadSaisons = async () => {
      const data = await fetchSaisons();
      setSaisons(data);
    };
    loadSaisons();
  }, []);

  // Lade Mannschaften basierend auf Liga und Saison
  useEffect(() => {
    const loadMannschaften = async () => {
      if (!liga || !saison) return;
      const data = await fetchMannschaften(liga, saison);
      setMannschaften(data);
    };
    loadMannschaften();
  }, [liga, saison]);

  // Überprüfe, ob das Spiel existiert
  useEffect(() => {
    const checkSpiel = async () => {
      if (!liga || !saison || !heimmannschaft || !gastmannschaft) return;

      setSpielLaden(true);
      console.log('Überprüfe Spiel:', { liga, saison, heimmannschaft, gastmannschaft });

      try {
        const data = await checkIfSpielExistiert(liga, saison, heimmannschaft, gastmannschaft);
        console.log('Spielprüfung Antwort:', data);
        if (data.length > 0) {
          setSpielExistiert(true);
          setSpielId(data[0].id);  // Verwende die ID des gefundenen Spiels
        } else {
          setSpielExistiert(false);
        }
      } catch (err) {
        console.error('Fehler beim Überprüfen des Spiels:', err);
      } finally {
        setSpielLaden(false);
      }
    };
    checkSpiel();
  }, [liga, saison, heimmannschaft, gastmannschaft]);

  // Spiel anlegen
  const handleSpielAnlegen = async () => {
    console.log('Versuche, ein neues Spiel anzulegen mit:', { liga, saison, heimmannschaft, gastmannschaft, spieltag });

    try {
      const newSpielId = await createSpiel(liga, saison, heimmannschaft, gastmannschaft, spieltag);
      console.log('Neues Spiel angelegt, ID:', newSpielId);
      setSpielId(newSpielId);  // Setze die neue Spiel-ID
      setSpielExistiert(true);
    } catch (err) {
      console.error('Fehler beim Anlegen des Spiels:', err);
    }
  };

  // Spiel zur Szene hinzufügen
  const handleSpielHinzufuegen = () => {
    if (spielId) {
      onSpielHinzufuegen(spielId);  // Callback-Funktion, um das Spiel zur Szene hinzuzufügen
    }
  };

  return (
    <div className="p-6 bg-base-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Spiel hinzufügen</h2>

      {/* Liga auswählen */}
      <SelectInput
        label="Liga"
        value={liga}
        onChange={(e) => setLiga(e.target.value)}
        options={ligen}
        required
      />

      {/* Saison auswählen */}
      <SelectInput
        label="Saison"
        value={saison}
        onChange={(e) => setSaison(e.target.value)}
        options={saisons}
        required
      />

      {/* Heimmannschaft auswählen */}
      <SelectInput
        label="Heimmannschaft"
        value={heimmannschaft}
        onChange={(e) => setHeimmannschaft(e.target.value)}
        options={mannschaften.map(team => ({ value: team.id, label: team.name }))}
        required
      />

      {/* Gastmannschaft auswählen */}
      <SelectInput
        label="Gastmannschaft"
        value={gastmannschaft}
        onChange={(e) => setGastmannschaft(e.target.value)}
        options={mannschaften.map(team => ({ value: team.id, label: team.name }))}
        required
      />

      {/* Spieltag eingeben */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Spieltag (optional)</label>
        <input
          type="text"
          className="input input-bordered w-full"
          value={spieltag}
          onChange={(e) => setSpieltag(e.target.value)}
        />
      </div>

      {/* Spiel anlegen oder überprüfen */}
      {!spielLaden && !spielExistiert && (
        <button
          type="button"
          className="btn btn-primary mb-4"
          onClick={handleSpielAnlegen}
        >
          Spiel anlegen
        </button>
      )}
      {spielLaden && <p>Lade Spielinformationen...</p>}
      {spielExistiert && <p>Spiel existiert bereits, ID: {spielId}</p>}

      {/* Spiel zur Szene hinzufügen */}
      {spielId && (
        <button
          type="button"
          className="btn btn-success"
          onClick={handleSpielHinzufuegen}
        >
          Spiel zur Szene hinzufügen
        </button>
      )}
    </div>
  );
};

export default SpielHinzufuegen;
