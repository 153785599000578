// src/components/PasswordResetForm.js
import React, { useState } from 'react';
import { message } from 'antd';
import emailjs from 'emailjs-com';

const PasswordResetForm = ({ onClose }) => {
  const [email, setEmail] = useState('');

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/custom-auth/reset-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        const resetLink = `${process.env.REACT_APP_FRONTEND_URL}/reset-password?code=${data.token}&email=${data.email}`;

        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_RESETPW,
          {
            email: data.email,
            reset_link: resetLink,
          },
          process.env.REACT_APP_EMAILJS_USER_ID
        );

        message.success("Reset-Link wurde per E-Mail gesendet.");
        onClose();
      } else {
        message.error(data?.error?.message || "Fehler beim Erstellen des Links.");
      }

    } catch (error) {
      console.error("Netzwerkfehler beim Zurücksetzen:", error);
      message.error("Fehler beim Zurücksetzen des Passworts.");
    }
  };

  return (
    <form className="card-body" onSubmit={handleReset}>
      <div className="form-control">
        <label className="label"><span className="label-text">Geben Sie Ihre Email ein</span></label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="input input-bordered"
          required
        />
      </div>
      <div className="form-control mt-6">
        <button type="submit" className="btn btn-primary">Passwort zurücksetzen</button>
      </div>
      <button type="button" onClick={onClose} className="btn btn-secondary mt-2">Abbrechen</button>
    </form>
  );
};

export default PasswordResetForm;
