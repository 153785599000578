import { createSlice } from '@reduxjs/toolkit';

const initialDashboardView = localStorage.getItem('dashboardView') || 'Meine Daten';

export const dashboardViewSlice = createSlice({
  name: 'dashboardView',
  initialState: {
    currentView: initialDashboardView,
  },
  reducers: {
    setDashboardView: (state, action) => {
      state.currentView = action.payload;
      localStorage.setItem('dashboardView', action.payload);
    },
  },
});

export const { setDashboardView } = dashboardViewSlice.actions;

export default dashboardViewSlice.reducer;
