import axiosInstance from "../components/axiosConfig";

export const generateThumbnailWithCanvas = (videoFile) => {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    video.src = URL.createObjectURL(videoFile);

    video.onloadeddata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      video.currentTime = video.duration / 2;

      video.onseeked = () => {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          const thumbnailFile = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
          resolve(thumbnailFile);
        }, 'image/jpeg');
      };
    };

    video.onerror = (err) => reject(err);
  });
};

export const handleVideoUpload = async (
  e,
  setLoading,
  setError,
  setSuccess,
  setVideoUrl,
  setThumbnailId,
  scene,
  onUpdate
) => {
  const file = e.target.files[0]; // Datei extrahieren
  if (!file) {
    setError('Keine Datei ausgewählt.');
    return;
  }

  console.log('Hochgeladene Datei:', file.name, file.size, file.type);

  // Setze den Zustand auf "Laden"
  setLoading(true);
  setError(null);
  setSuccess(null);

  try {
    // Schritt 1: Video hochladen (ohne Ordner)
    const formData = new FormData();
    formData.append('files', file); 

    const uploadVideoRes = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (!uploadVideoRes.data || uploadVideoRes.data.length === 0 || !uploadVideoRes.data[0].id) {
      throw new Error('Keine Videodaten erhalten oder ID fehlt.');
    }

    const videoId = uploadVideoRes.data[0].id;
    console.log('Video erfolgreich hochgeladen. Video-ID:', videoId);

    // Schritt 2: Thumbnail generieren
    const thumbnailFile = await generateThumbnailWithCanvas(file);

    const formDataThumbnail = new FormData();
    formDataThumbnail.append('files', thumbnailFile);

    const uploadThumbnailRes = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/upload`,
      formDataThumbnail,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (!uploadThumbnailRes.data || uploadThumbnailRes.data.length === 0 || !uploadThumbnailRes.data[0].id) {
      throw new Error('Keine Thumbnail-Daten erhalten oder ID fehlt.');
    }

    const thumbnailId = uploadThumbnailRes.data[0].id;
    console.log('Thumbnail erfolgreich hochgeladen. Thumbnail-ID:', thumbnailId);

    // Schritt 3: Szene mit Video, Thumbnail und Status aktualisieren
    if (!scene || !scene.id) {
      throw new Error('Ungültige Szenendaten. Szene oder Szene-ID fehlen.');
    }

    const updateSceneData = {
      data: {
        Video: videoId,
        thumbnail: thumbnailId,
        arbeitsstatus: 'geschnitten', // Status auf "geschnitten" setzen
      },
    };

    await axiosInstance.put(
      `${process.env.REACT_APP_API_URL}/videoszenes/${scene.id}`,
      updateSceneData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );

    setVideoUrl(uploadVideoRes.data[0].url); 
    setThumbnailId(thumbnailId); 
    setSuccess('Video, Thumbnail und Status erfolgreich aktualisiert!');
    if (onUpdate) onUpdate(); 

  } catch (err) {
    console.error('Fehler beim Hochladen:', err);
    if (err.response && err.response.data && err.response.data.error) {
      setError(`Fehler beim Hochladen des Videos: ${err.response.data.error.message}`);
    } else {
      setError('Unbekannter Fehler beim Hochladen des Videos.');
    }
  } finally {
    setLoading(false); 
  }
};



export const deleteVideoAndThumbnail = async (videoId, thumbnailId) => {
  try {
    // Lösche das Video auf Strapi (falls vorhanden)
    if (videoId) {
      await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/upload/files/${videoId}`);
      console.log('Video erfolgreich gelöscht.');
    }

    // Lösche das Thumbnail auf Strapi (falls vorhanden)
    if (thumbnailId) {
      await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/upload/files/${thumbnailId}`);
      console.log('Thumbnail erfolgreich gelöscht.');
    }

  } catch (error) {
    console.error('Fehler beim Löschen des Videos oder Thumbnails:', error);
    throw new Error('Löschen des Videos oder Thumbnails fehlgeschlagen.');
  }
};

export const getSignedVideoUrl = async (unsignierteUrl) => {
  try {
    const response = await fetch('/api/get-signed-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: unsignierteUrl }),
    });

    if (!response.ok) {
      throw new Error('Fehler beim Abrufen der signierten URL');
    }

    const data = await response.json();
    return data.signedUrl;
  } catch (error) {
    console.error('Fehler beim Abrufen der signierten URL:', error);
    throw error;
  }
};

