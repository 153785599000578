import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDashboardView } from '../redux/dashboardViewSlice';
import { setUser } from '../redux/userSlice'; 
import MeineDaten from './MeineDaten';
import MeineSpiele from './MeineSpiele';
import VideoszenenVerwalten from './VideoszenenVerwalten';
import Statistiken from './Statistiken';
import Userdaten from './UserDaten';
import ReleaseManager from './ReleaseManager';
import Vereinsverwaltung from './Vereinsverwaltung';
import Zugehoerigkeiten from './Zugehoerigkeiten';
import SystemParameter from './SystemParameter'; 
import axiosInstance from './axiosConfig';

const Dashboard = () => {
  const dispatch = useDispatch();
  const currentView = useSelector((state) => state.dashboardView?.currentView);
  const user = useSelector((state) => state.user?.user); 

  const [isMenuOpen, setIsMenuOpen] = useState(false); 

  const handleClick = (view) => {
    dispatch(setDashboardView(view));
    setIsMenuOpen(false); 
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get('/users/me?populate=*');
        const userData = response.data;
        console.log('Benutzerinformationen:', userData);
        dispatch(setUser(userData));
        if (userData && userData.sr_rolle) {
          const roles = userData.sr_rolle.map((role) => role.rolle);
          console.log('Benutzerrollen:', roles); 
        } else {
          console.log('Keine Rollen für diesen Benutzer gefunden.');
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Benutzerinformationen:', error);
      }
    };
    fetchUserData(); 
  }, [dispatch]);

  const hasRole = (roles) => {
    return user?.sr_rolle?.some((role) => roles.includes(role.rolle));
  };

  const renderComponent = () => {
    switch (currentView) {
      case 'Meine Daten':
        return <MeineDaten />;
      case 'Meine Spiele':
        return <MeineSpiele />;
      case 'Videoszenen verwalten':
        return <VideoszenenVerwalten />;
      case 'Statistiken':
        return <Statistiken />;
      case 'Userdaten':
        return <Userdaten />;
      case 'ReleaseManager':
        return <ReleaseManager />;
      case 'Vereinsverwaltung':
        return <Vereinsverwaltung />;
      case 'Zugehoerigkeiten':
        return <Zugehoerigkeiten />;
      case 'SystemParameter':
        return <SystemParameter />;
      default:
        return <MeineDaten />;
    }
  };

  return (
    <div className="flex flex-col w-full">
      {/* Navbar */}
      <div className="lg:hidden p-4 w-11/12 mx-auto bg-base-200 rounded-lg shadow-lg flex justify-center">
      <button 
        className="text-white focus:outline-none" 
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>

      {/* Menü-Overlay bei geöffnetem Menü */}
      {isMenuOpen && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-50" onClick={() => setIsMenuOpen(false)}></div>
      )}

      <div className="flex justify-center relative">
        <div className="w-11/12 lg:w-11/12 flex">
          {/* Sidebar */}
          <div className={`lg:w-1/6 bg-base-200 min-w-[250px] rounded-box lg:block ${isMenuOpen ? 'block fixed top-0 left-0 h-full z-50 w-11/12' : 'hidden'} lg:flex`}>
            <ul className="menu bg-base-200 rounded-box w-full">
              <li><a onClick={() => handleClick('Meine Daten')}>Meine Daten</a></li>
              <li><a onClick={() => handleClick('Meine Spiele')}>Meine Spiele</a></li>

              {hasRole(['KT']) && (
                <>
                  <li>
                    <h2 className="menu-title">Videoszenenverwaltung</h2>
                    <ul>
                      <li><a onClick={() => handleClick('Videoszenen verwalten')}>Videoszenen verwalten</a></li>
                      <li><a onClick={() => handleClick('ReleaseManager')}>Release Manager</a></li>
                    </ul>
                  </li>
                </>
              )}

              {hasRole(['KT', 'VSA', 'BSA']) && (
                <li><a onClick={() => handleClick('Statistiken')}>Statistiken</a></li>
              )}
              {hasRole(['KT', 'VSA', 'BSA']) && (
                <li><a onClick={() => handleClick('Userdaten')}>Userdaten</a></li>
              )}

              {hasRole(['KT']) && (
                <>
                  <li>
                    <h2 className="menu-title">Ligaverwaltung</h2>
                    <ul>
                      <li><a onClick={() => handleClick('Vereinsverwaltung')}>Vereinsverwaltung</a></li>
                      <li><a onClick={() => handleClick('Zugehoerigkeiten')}>Zugehoerigkeiten</a></li>
                    </ul>
                  </li>
                </>
              )}

              {hasRole(['KT']) && (
                <>
                  <li>
                    <h2 className="menu-title">Systemverwaltung</h2>
                    <ul>
                      <li><a onClick={() => handleClick('SystemParameter')}>Systemparameter</a></li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
         </div>

          {/* Hauptinhalt */}
          <div className="w-full lg:w-5/6 p-5 overflow-auto">
            {renderComponent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
