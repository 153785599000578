import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Verwende die konfigurierte axiosInstance

const VideoView = ({ sceneId, onDelete }) => {
  const [videoUrl, setVideoUrl] = useState(null); // Zustand für die signierte URL
  const [loading, setLoading] = useState(true);   // Zustand für das Laden
  const [error, setError] = useState(null);       // Zustand für Fehler

  useEffect(() => {
    const fetchSceneData = async () => {
      try {
        // Abrufen der Szenen-Daten mit axios
        const response = await axiosInstance.get(`/videoszenes/${sceneId}?populate=*`);
        const data = response.data;

        // Stelle sicher, dass die Video-Daten vorhanden sind
        if (data.data.attributes.Video && data.data.attributes.Video.data) {
          const videoHash = data.data.attributes.Video.data.attributes.hash; // Den Hash des Videos extrahieren
          const videoExt = data.data.attributes.Video.data.attributes.ext;   // Die Dateiendung extrahieren
          const videoKey = `${videoHash}${videoExt}`;  // Erstelle den Dateinamen

          console.log("Video-Hash:", videoKey);

          // Abrufen der signierten Video-URL mit dem Hash des Videos
          const videoResponse = await axiosInstance.get(`/get-signed-video-url/${videoKey}`);
          const videoData = videoResponse.data;

          console.log("Signierte Video-URL abgerufen:", videoData.signedUrl);

          // Setze die signierte URL in den Zustand
          setVideoUrl(videoData.signedUrl);
        } else {
          throw new Error("Kein Video gefunden");
        }
        setLoading(false); // Laden abgeschlossen
      } catch (error) {
        console.error("Fehler beim Abrufen der Szenendaten:", error);
        setError("Fehler beim Abrufen der Szenendaten");
        setLoading(false); // Bei Fehler ebenfalls Ladezustand beenden
      }
    };

    // Rufe die Funktion auf, um die Szene-Daten zu laden
    fetchSceneData();
  }, [sceneId]);

  if (loading) {
    return <p>Laden...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="mb-6 mt-6">
      {videoUrl ? (
        <video controls className="w-full rounded-lg shadow-lg" style={{ aspectRatio: '16/9' }}>
          <source src={videoUrl} type="video/mp4" />
          Dein Browser unterstützt kein Video-Tag.
        </video>
      ) : (
        <p>Kein Video verfügbar.</p>
      )}
      <button
        type="button"
        className="btn btn-error w-full md:w-auto mt-4"
        onClick={onDelete} // Rufe die Löschfunktion auf
      >
        Video löschen
      </button>
    </div>
  );
};

export default VideoView;

