import axiosInstance from "../axiosConfig";

export const fetchAllClubs = async () => {
  let clubs = [];
  let page = 1;
  const pageSize = 100; // Kann an die Anzahl der gewünschten Ergebnisse pro Seite angepasst werden
  let hasMore = true;

  try {
    while (hasMore) {
      // API-Anfrage mit Pagination
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/vereins`, { // Überprüfe, ob die Route korrekt ist
        params: {
          'pagination[page]': page,
          'pagination[pageSize]': pageSize,
        },
      });

      if (response.data && response.data.data) {
        // Füge die neuen Clubs zur Liste hinzu
        clubs = [...clubs, ...response.data.data];
        
        // Überprüfe, ob es noch weitere Seiten gibt
        const totalPages = response.data.meta.pagination.pageCount;
        page += 1;
        hasMore = page <= totalPages;
      } else {
        hasMore = false; // Beende die Schleife, wenn keine Daten mehr vorhanden sind
      }
    }

    // Verarbeite die Vereinsdaten in einem für die Select-Komponente geeigneten Format
    const clubOptions = clubs.map(club => ({
      value: club.id,
      label: club.attributes.name, // Falls der Name des Vereins in "attributes.name" gespeichert ist
      liga: club.attributes.liga?.data?.attributes?.name, // Liga, wenn vorhanden
    }));

    return clubOptions;

  } catch (error) {
    console.error('Fehler beim Abrufen der Vereinsdaten:', error);
    return [];
  }
};
