// src/redux/store.js

import { configureStore } from '@reduxjs/toolkit';
import viewReducer from './viewSlice';
import dashboardViewReducer from './dashboardViewSlice';
import videoSceneReducer from './videoSceneSlice';
import userReducer from './userSlice'; // Importiere den userSlice

export const store = configureStore({
  reducer: {
    view: viewReducer,
    dashboardView: dashboardViewReducer,
    videoScene: videoSceneReducer,
    user: userReducer, // Füge den userSlice zum Store hinzu
  },
});

export default store;
