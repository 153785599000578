import React from 'react';

const TabNavigation = ({ activeTab, handleTabChange }) => {
  return (
    <div className="flex justify-center mb-6">
      <div className="tabs tabs-boxed">
        <a
          role="tab"
          className={`tab tab-sm ${activeTab === 'Thema' ? 'tab-active' : ''}`}
          onClick={() => handleTabChange('Thema')}
        >
          Thema
        </a>
        <a
          role="tab"
          className={`tab tab-sm ${activeTab === 'Bewertung' ? 'tab-active' : ''}`}
          onClick={() => handleTabChange('Bewertung')}
        >
          Bewertung
        </a>
        <a
          role="tab"
          className={`tab tab-sm ${activeTab === 'Verein/Liga' ? 'tab-active' : ''}`}
          onClick={() => handleTabChange('Verein/Liga')}
        >
          Verein/Liga
        </a>
      </div>
    </div>
  );
};

export default TabNavigation;
