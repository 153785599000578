import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import { message } from 'antd';
import axiosInstance from '../axiosConfig';

// Utilities zur Handhabung von Tokens
export const getToken = () => localStorage.getItem('authToken'); // Stelle sicher, dass der Schlüssel 'authToken' stimmt
export const setToken = (token) => localStorage.setItem('authToken', token);
export const removeToken = () => localStorage.removeItem('authToken');

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Verwende useNavigate

  // Verwende die Umgebungsvariable für die API-URL
  const apiUrl = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const authToken = getToken();

    if (authToken) {
      console.log("AuthProvider: Token gefunden, Benutzerdaten werden abgerufen...");
      fetchLoggedInUser(authToken);
    } else {
      console.log("AuthProvider: Kein Token gefunden, Weiterleitung zur Startseite.");
      navigate('/'); // Redirect zu Home, wenn kein Token vorhanden
      setIsLoading(false);
    }
  }, [navigate]);

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("AuthProvider: Benutzerdaten erfolgreich abgerufen:", data);
        setUserData(data);
        setIsLoading(false);
      } else {
        console.error("AuthProvider: Benutzerdaten konnten nicht abgerufen werden.");
        removeToken();
        message.error("Benutzerdaten konnten nicht abgerufen werden.");
        navigate('/'); // Redirect zu Home bei ungültigem Token
      }
    } catch (error) {
      console.error("AuthProvider: Fehler beim Abrufen der Benutzerdaten:", error);
      message.error("Fehler beim Abrufen der Benutzerdaten.");
      removeToken();
      navigate('/'); // Redirect zu Home bei Fehler
    } finally {
      setIsLoading(false);
      console.log("AuthProvider: Ladezustand beendet.");
    }
  };

  const login = async (identifier, password) => {
    try {
      // Konvertiere den Benutzernamen in Kleinbuchstaben
      const lowercaseIdentifier = identifier.toLowerCase();
  
      const response = await fetch(`${apiUrl}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier: lowercaseIdentifier, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        
        if (data.user.blocked) {
          return { blocked: true };  // Gebe den blockierten Status zurück
        }
  
        // Speichern des Tokens im localStorage
        setToken(data.jwt);

        // Überprüfe, ob der Benutzer bestätigt werden muss
        if (!data.user.confirmed) {
          console.log(data.user.confirmed);
          await confirmUser(data.user.id); // Funktion zum Bestätigen des Benutzers
        }
  
        // Benutzerdaten setzen
        setUserData(data.user);
  
        message.success("Erfolgreich eingeloggt.");
        return data.user;
      } else {
        const errorData = await response.json(); // Fehlerdetails abrufen
        console.error("AuthProvider: Login fehlgeschlagen:", errorData);
        message.error(errorData.message || "Fehler bei der Anmeldung.");
        return null;
      }
    } catch (error) {
      console.error("AuthProvider: Fehler beim Login:", error);
      message.error("Fehler bei der Anmeldung.");
      return null;
    }
  };
  
  
  const confirmUser = async (userId) => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error("Token fehlt");
      }
  
      const response = await axiosInstance.put(`/users/${userId}`, {
        confirmed: true,
      }, {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      console.log("Benutzer erfolgreich bestätigt:", response.data);
    } catch (error) {
      if (error.response) {
        console.error("Fehler beim Bestätigen des Benutzers:", error.response.data);
        throw new Error(error.response.data.error.message || "Fehler beim Bestätigen des Benutzers");
      } else {
        console.error("Fehler bei der Bestätigung des Benutzers:", error.message);
      }
    }
  };
  
  

  
  const logout = () => {
    removeToken(); // Token aus localStorage entfernen
    setUserData(null);
    console.log("AuthProvider: Benutzer ausgeloggt und Token entfernt.");
    message.success("Erfolgreich ausgeloggt.");
    navigate('/'); // Navigation nach dem Logout zur Home-Seite
  };

  return (
    <AuthContext.Provider value={{ user: userData, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
