import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig';

const EmailListe = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/users?filters[notifications][$eq]=true'); // API-Endpoint anpassen
        setUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Fehler beim Laden der Benutzerliste.');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-error shadow-lg">
        <div>
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Email-Liste</h1>
      <div className="overflow-x-auto">
        <table className="table w-full">
          {/* Tabellenkopf */}
          <thead>
            <tr>
              <th>#</th>
              <th>Vorname</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          {/* Tabellenkörper */}
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.vorname}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmailListe;
