import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';

const MostViewedScenes = () => {
  const [mostViewedScenes, setMostViewedScenes] = useState([]); // Initial leeres Array

  useEffect(() => {
    const fetchMostViewedScenes = async () => {
      try {
        let allViewsData = [];
        let page = 1;
        let hasMoreData = true;

        // Schleife durch alle Seiten, solange es noch Daten gibt
        while (hasMoreData) {
          const response = await axiosInstance.get(`/videoszene-views?populate=*&pagination[page]=${page}&pagination[pageSize]=100`); // Passe den Endpunkt an und setze pageSize für Anzahl der Einträge pro Seite
          const viewsData = response.data.data; // Array der Daten von dieser Seite

          if (viewsData.length > 0) {
            allViewsData = [...allViewsData, ...viewsData]; // Kombiniere mit bisherigen Daten
            page += 1; // Erhöhe die Seite für die nächste Anfrage
            hasMoreData = viewsData.length === 100; // Fortfahren, falls Seite voll ist
          } else {
            hasMoreData = false; // Keine weiteren Daten vorhanden
          }
        }

        // Prüfen, ob es Daten gibt und falls ja, aggregieren
        if (!allViewsData.length) {
          setMostViewedScenes([]); // Falls keine Daten vorliegen, setze ein leeres Array
        } else {
          // Zählen, wie oft jede videoszene.id vorkommt
          const sceneCountMap = allViewsData.reduce((acc, view) => {
            const sceneId = view.attributes.videoszene.data.id;
            const sceneTitle = view.attributes.videoszene.data.attributes.titel;

            if (acc[sceneId]) {
              acc[sceneId].viewCount += 1;
            } else {
              acc[sceneId] = {
                id: sceneId,
                titel: sceneTitle,
                viewCount: 1,
              };
            }
            return acc;
          }, {});

          // Szene-Objekte in ein Array umwandeln und nach Anzahl der Views sortieren
          const sortedScenes = Object.values(sceneCountMap).sort((a, b) => b.viewCount - a.viewCount);

          setMostViewedScenes(sortedScenes.slice(0, 5)); // Top 5 meistgesehenen Szenen
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        setMostViewedScenes([]); // Fallback auf ein leeres Array bei Fehler
      }
    };

    fetchMostViewedScenes();
  }, []);

  if (mostViewedScenes.length === 0) {
    return <div>Keine Daten verfügbar</div>; // Platzhalter, wenn keine Daten verfügbar sind
  }

  return (
    <div className="card bg-base-100 shadow-md p-4">
      <h2 className="card-title">Meistgesehene Szenen aller Zeiten</h2>
      <ul>
        {mostViewedScenes.map((scene) => (
          <li key={scene.id} className="my-2">
            <div className="flex justify-between">
              <span>{scene.titel}</span>
              <span>{scene.viewCount} Views</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MostViewedScenes;
