import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from './AuthProvider/AuthProvider'; // Annahme: AuthProvider ist in diesem Pfad
import { message } from 'antd'; // Für Benachrichtigungen

const HeroDefault = () => {
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState(''); // Für Email/Benutzernamen
  const [password, setPassword] = useState(''); // Für Passwort

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginResponse = await login(identifier, password);

      if (loginResponse && !loginResponse.blocked) {
        // Weiterleitung bei erfolgreichem Login und wenn der Benutzer nicht blockiert ist
        navigate('/'); // Weiterleitung zur Startseite oder einer anderen Seite
      } else if (loginResponse && loginResponse.blocked) {
        // Benutzer ist blockiert
        message.error('Ihr Konto ist inaktiv. Bitte kontaktieren Sie den Support.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      message.error('Fehler bei der Anmeldung.');
    }
  };

  return (
    <div className="hero bg-base-200 min-h-screen w-11/12 md:w-11/12 lg:w-11/12 xl:w-11/12 flex shadow-lg rounded-lg bg-white">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold">Willkommen auf unserem Videoportal!</h1>
          <p className="py-6">
            Bleibe immer auf dem aktuellen Stand der Regelauslegung mit dem BFV Videoportal
          </p>
        </div>
        <div className="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl">
          <form className="card-body" onSubmit={handleSubmit}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email/Username</span>
              </label>
              <input
                type="text"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                placeholder="Email/Username"
                className="input input-bordered"
                required
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Passwort</span>
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Passwort"
                className="input input-bordered"
                required
              />
              <label className="label">
                <a href="#" className="label-text-alt link link-hover">Passwort vergessen?</a>
              </label>
            </div>
            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroDefault;
