import React, { useState, useEffect } from 'react';
import { fetchLigen, fetchSaisons, fetchVereineByLigaUndSaison, checkIfSpielExistiert, createSpiel } from './api/api'; // Neue API für Vereine basierend auf Liga und Saison
import SelectInput from './shared/SelectInput';

const SpielAuswahl = ({
  onSpielIdChange,
  initialLiga = '',
  initialSaison = '',
  initialHeimmannschaft = '',
  initialGastmannschaft = '',
  initialSpieltag = ''
}) => {
  const [liga, setLiga] = useState(initialLiga);
  const [ligen, setLigen] = useState([]);
  const [saison, setSaison] = useState(initialSaison);
  const [saisons, setSaisons] = useState([]);
  const [heimmannschaft, setHeimmannschaft] = useState(initialHeimmannschaft);
  const [gastmannschaft, setGastmannschaft] = useState(initialGastmannschaft);
  const [vereine, setVereine] = useState([]); // Vereine, die basierend auf Liga und Saison geladen werden
  const [spieltag, setSpieltag] = useState(initialSpieltag);
  const [spielLaden, setSpielLaden] = useState(false);
  const [spielId, setSpielId] = useState(null);

  // Lade Ligen
  useEffect(() => {
    const loadLigen = async () => {
      const data = await fetchLigen();
      setLigen(data);
    };
    loadLigen();
  }, []);

  // Lade Saisons
  useEffect(() => {
    const loadSaisons = async () => {
      const data = await fetchSaisons();
      setSaisons(data);
    };
    loadSaisons();
  }, []);

  // Lade Vereine basierend auf Liga und Saison
  useEffect(() => {
    const loadVereine = async () => {
      if (!liga || !saison) return;
      const data = await fetchVereineByLigaUndSaison(liga, saison); // API-Aufruf, um Vereine basierend auf Liga und Saison zu laden
      setVereine(data); // Setze die geladenen Vereine
    };
    loadVereine();
  }, [liga, saison]);

  // Überprüfe, ob das Spiel existiert oder erstelle es, wenn es nicht existiert
  useEffect(() => {
    const checkSpiel = async () => {
      if (!liga || !saison || !heimmannschaft || !gastmannschaft) return;

      setSpielLaden(true);
      console.log('Überprüfe Spiel:', { liga, saison, heimmannschaft, gastmannschaft });

      try {
        const data = await checkIfSpielExistiert(liga, saison, heimmannschaft, gastmannschaft);
        console.log('Spielprüfung Antwort:', data);

        if (data.length > 0) {
          // Wenn das Spiel existiert, setze die Spiel-ID
          setSpielId(data[0].id);
          onSpielIdChange(data[0].id);  // Spiel ID zurückgeben
        } else {
          // Wenn das Spiel nicht existiert, lege es an
          const newSpielId = await createSpiel(liga, saison, heimmannschaft, gastmannschaft, spieltag);
          console.log('Neues Spiel angelegt, ID:', newSpielId);

          setSpielId(newSpielId);
          onSpielIdChange(newSpielId);  // Die ID des erstellten Spiels zurückgeben
        }
      } catch (err) {
        console.error('Fehler beim Überprüfen/Erstellen des Spiels:', err);
      } finally {
        setSpielLaden(false);
      }
    };
    checkSpiel();
  }, [liga, saison, heimmannschaft, gastmannschaft, spieltag, onSpielIdChange]);

  return (
    <div>
      <h3 className="text-xl font-bold mb-4">Spielauswahl</h3>

      {/* Liga auswählen */}
      <SelectInput
        label="Liga"
        value={liga}
        onChange={(e) => setLiga(e.target.value)}
        options={ligen}
        required
      />

      {/* Saison auswählen */}
      <SelectInput
        label="Saison"
        value={saison}
        onChange={(e) => setSaison(e.target.value)}
        options={saisons}
        required
      />

      {/* Heimmannschaft auswählen */}
      <SelectInput
        label="Heimmannschaft"
        value={heimmannschaft}
        onChange={(e) => setHeimmannschaft(e.target.value)}
        options={vereine.map(verein => ({ value: verein.id, label: verein.name }))}
        required
      />

      {/* Gastmannschaft auswählen */}
      <SelectInput
        label="Gastmannschaft"
        value={gastmannschaft}
        onChange={(e) => setGastmannschaft(e.target.value)}
        options={vereine.map(verein => ({ value: verein.id, label: verein.name }))}
        required
      />

      {/* Spieltag eingeben */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Spieltag (optional)</label>
        <input
          type="text"
          className="input input-bordered w-full"
          value={spieltag}
          onChange={(e) => setSpieltag(e.target.value)}
        />
      </div>

      {spielLaden && <p>Lade Spielinformationen...</p>}
      {spielId && <p>Spiel ausgewählt, ID: {spielId}</p>}
    </div>
  );
};

export default SpielAuswahl;

