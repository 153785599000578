// src/components/SystemParameter.js
import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';

const SystemParameter = () => {
  const [defaultSeason, setDefaultSeason] = useState('');
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    // Laden der aktuellen Systemparameter und der verfügbaren Saisons
    const fetchSystemParameterAndSeasons = async () => {
      try {
        const [systemResponse, seasonsResponse] = await Promise.all([
          axiosInstance.get(`${process.env.REACT_APP_API_URL}/systemparameters`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }),
          axiosInstance.get(`${process.env.REACT_APP_API_URL}/saisons`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          })
        ]);

        // Setze die aktuelle Saison als Standardwert
        if (systemResponse.data.data.attributes.saison.data) {
          setDefaultSeason(systemResponse.data.data.attributes.saison.data.id);
        }

        // Setze die Liste der verfügbaren Saisons
        setSeasons(seasonsResponse.data.data);

      } catch (err) {
        setError('Fehler beim Laden der Systemparameter');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSystemParameterAndSeasons();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Aktualisieren der Systemparameter
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/systemparameters`,
        {
          data: {
            saison: defaultSeason,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      setSuccess('Systemparameter erfolgreich aktualisiert!');
    } catch (err) {
      setError('Fehler beim Aktualisieren der Systemparameter');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-base-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Systemparameter pflegen</h2>

      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}

      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Standard-Saison</label>
        <select
          className="select select-bordered w-full"
          value={defaultSeason}
          onChange={(e) => setDefaultSeason(e.target.value)}
          disabled={loading}
        >
          <option value="" disabled>
            Wähle eine Saison
          </option>
          {seasons.map((season) => (
            <option key={season.id} value={season.id}>
              {season.attributes.titel}
            </option>
          ))}
        </select>
      </div>

      <button
        className="btn btn-success"
        onClick={handleSave}
        disabled={loading}
      >
        {loading ? 'Speichern...' : 'Speichern'}
      </button>
    </div>
  );
};

export default SystemParameter;
