import React, { useState } from 'react';

const SceneDetails = ({
  beschreibung,
  spielstrafe,
  persoenlicheStrafe,
  srBewertungen = [], // Standardwerte setzen
  sra1Bewertungen = [], // Standardwerte setzen
  sra2Bewertungen = [], // Standardwerte setzen
  renderRatingRadioButtons,
}) => {
  const [activeTab, setActiveTab] = useState('beschreibung');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  

  return (
    <div className="w-full max-w-3xl mx-auto">
      {/* Tab-Navigation */}
      <div role="tablist" className="tabs tabs-bordered justify-center">
        <a
          role="tab"
          className={`tab ${
            activeTab === 'beschreibung'
              ? 'tab-active text-black font-bold [--tab-bg:var(--tw-colors-primary)] [--tab-border-color:var(--tw-colors-primary)]'
              : ''
          }`}
          onClick={() => handleTabClick('beschreibung')}
        >
          Beschreibung
        </a>
        <a
          role="tab"
          className={`tab ${
            activeTab === 'entscheidung'
              ? 'tab-active text-black font-bold [--tab-bg:var(--tw-colors-primary)] [--tab-border-color:var(--tw-colors-primary)]'
              : ''
          }`}
          onClick={() => handleTabClick('entscheidung')}
        >
          Entscheidung
        </a>
        <a
          role="tab"
          className={`tab ${
            activeTab === 'bewertungen'
              ? 'tab-active text-black font-bold [--tab-bg:var(--tw-colors-primary)] [--tab-border-color:var(--tw-colors-primary)]'
              : ''
          }`}
          onClick={() => handleTabClick('bewertungen')}
        >
          Bewertungen
        </a>
      </div>

      {/* Tab-Inhalte */}
      {activeTab === 'beschreibung' && (
        <div className="card bg-base-100 shadow-xl p-4 md:p-6 mb-6">
          <div className="card-body">
            <h3 className="card-title text-2xl font-bold mt-4 mb-4">Beschreibung</h3>
            <p className="text-justify prose mb-4 hyphens-auto text-sm md:text-base leading-relaxed">
              {beschreibung || 'Keine Beschreibung verfügbar.'}
            </p>
          </div>
        </div>
      )}

      {activeTab === 'entscheidung' && (
        <div className="card bg-base-100 shadow-xl p-4 md:p-6 mb-6">
          <div className="card-body">
            <h3 className="card-title text-2xl font-bold mt-4 mb-4">Entscheidung</h3>
            <div className="text-sm md:text-base">
              <p className="mb-2">
                <span className="font-semibold">Spielstrafe:</span> {spielstrafe || 'Keine Angabe'}
              </p>
              <p className="mb-4">
                <span className="font-semibold">Persönliche Strafe:</span> {persoenlicheStrafe || 'Keine Angabe'}
              </p>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'bewertungen' && (
        <div className="mb-6">
          <h3 className="text-2xl font-bold mb-4 mt-4">Bewertungen</h3>

          {/* Sektion für Schiedsrichter */}
          <div className="card shadow-lg p-4 mb-4">
            <h4 className="text-xl font-semibold mb-2">Schiedsrichter</h4>
            {srBewertungen.length > 0 ? (
              srBewertungen.map((bewertung) => (
                <div key={bewertung.id} className="">
                  <p>{bewertung.Kategorie}</p>
                  {renderRatingRadioButtons(bewertung)}
                </div>
              ))
            ) : (
              <p className="text-sm">Keine Bewertungen vorhanden.</p>
            )}
          </div>

          {/* Sektion für Schiedsrichter-Assistent 1 */}
          <div className="card shadow-lg p-4 mb-4">
            <h4 className="text-xl font-semibold mb-2">Schiedsrichter-Assistent 1</h4>
            {sra1Bewertungen.length > 0 ? (
              sra1Bewertungen.map((bewertung) => (
                <div key={bewertung.id} className="mb-4">
                  <p>{bewertung.Kategorie}</p>
                  {renderRatingRadioButtons(bewertung)}
                </div>
              ))
            ) : (
              <p className="text-sm">Keine Bewertungen vorhanden.</p>
            )}
          </div>

          {/* Sektion für Schiedsrichter-Assistent 2 */}
          <div className="card shadow-lg p-4">
            <h4 className="text-xl font-semibold mb-2">Schiedsrichter-Assistent 2</h4>
            {sra2Bewertungen.length > 0 ? (
              sra2Bewertungen.map((bewertung) => (
                <div key={bewertung.id} className="mb-4 mt-2">
                  <p className="mb-2">{bewertung.Kategorie}</p>
                  {renderRatingRadioButtons(bewertung)}
                </div>
              ))
            ) : (
              <p className="text-sm">Keine Bewertungen vorhanden.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SceneDetails;
