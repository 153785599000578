import React, { useState, useEffect } from 'react';
import qs from 'qs'; // Importiere die qs Library
import SzeneKarte from './SzeneKarte';
import FilterComponent from './FilterComponent';
import Pagination from './Pagination';
import TabNavigation from './TabNavigation';
import useFetchData from './hooks/useFetchData';
import { useNavigate } from 'react-router-dom';
import { tagOptions } from './constants/tagOptions'; // Importiere die tagOptions
import { kategorien } from './constants/kategorien'; // Importiere die kategorien
import axiosInstance from './axiosConfig';
import { fetchAllVideoszenes } from './api/api';

const AlleSzenen = () => {
  const { seasons, leagues, clubs } = useFetchData();
  const [filteredScenes, setFilteredScenes] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [activeTab, setActiveTab] = useState('Thema');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [minRating, setMinRating] = useState(1);
  const [maxRating, setMaxRating] = useState(6);
  const [selectedLeague, setSelectedLeague] = useState('');
  const [selectedClub, setSelectedClub] = useState('');
  const [selectedSeason, setSelectedSeason] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const navigate = useNavigate();

  const handleNavigate = (scene) => {
    navigate(`/videoszene/${scene.id}`, { state: { from: '/alle-szenen' } });
  };

  const fetchSpielIds = async () => {
    try {
      const filters = {};
      if (selectedSeason) {
        filters['saison'] = { titel: { $eq: selectedSeason } };
      }
      if (selectedLeague) {
        filters['liga'] = { Name: { $eq: selectedLeague } };
      }
      if (selectedClub) {
        filters['$or'] = [
          { 'heimverein': { name: { $eq: selectedClub } } },
          { 'gastverein': { name: { $eq: selectedClub } } }
        ];
      }

      const query = qs.stringify({ filters }, { encodeValuesOnly: true, arrayFormat: 'brackets' });
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/spiels?${query}`);
      
      if (response.data && response.data.data) {
        return response.data.data.map(spiel => spiel.id);
      } else {
        console.error('Erwartete Datenstruktur, aber die Antwort war:', response.data);
        return [];
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Spiel-IDs:', error);
      return [];
    }
  };

  const fetchFilteredScenes = async () => {
    try {
      let spielIds = [];
      if (activeTab === 'Verein/Liga') {
        spielIds = await fetchSpielIds();
      }

      const filters = {
        arbeitsstatus: { $eq: 'published' },
        ...(spielIds.length > 0 && { spiel: { id: { $in: spielIds } } }),
        ...(selectedCategory && { Bewertung: { Kategorie: { $eq: selectedCategory } } }),
        ...(selectedTheme.length > 0 && { thema: { name: { $in: selectedTheme } } }),
      };

      const query = qs.stringify({
        filters,
        sort: ['updatedAt:desc'],
        pagination: { page: currentPage, pageSize: itemsPerPage },
        populate: '*',
      }, { encodeValuesOnly: true, arrayFormat: 'brackets' });

      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/videoszenes?${query}`);
      
      if (response.data && response.data.data) {
        const filteredByRating = response.data.data.filter(scene =>
          scene.attributes.Bewertung.some(bewertung =>
            bewertung.Bewertung >= minRating && bewertung.Bewertung <= maxRating
          )
        );
        setFilteredScenes(filteredByRating);
        setTotalItems(response.data.meta.pagination.total); // Gesamtanzahl aktualisieren
      } else {
        setFilteredScenes([]);
        setTotalItems(0);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Szenen:', error);
      setFilteredScenes([]);
      setTotalItems(0);
    }
  };

  const loadScenes = async () => {
    if (!selectedCategory && selectedTheme.length === 0 && minRating === 1 && maxRating === 6 && !selectedLeague && !selectedClub && !selectedSeason) {
      const allScenes = await fetchAllVideoszenes();
      setFilteredScenes(allScenes);
      setTotalItems(allScenes.length);
    } else {
      await fetchFilteredScenes();
    }
  };

  useEffect(() => {
    loadScenes();
  }, [selectedCategory, selectedTheme, minRating, maxRating, selectedLeague, selectedClub, selectedSeason, currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedScenes = filteredScenes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="w-11/12 mx-auto">
      <h2 className="text-3xl font-bold mb-6">Alle Szenen</h2>

      <TabNavigation activeTab={activeTab} handleTabChange={setActiveTab} />

      <FilterComponent
        activeTab={activeTab}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedTheme={selectedTheme}
        setSelectedTheme={setSelectedTheme}
        seasons={seasons}
        selectedSeason={selectedSeason}
        setSelectedSeason={setSelectedSeason}
        leagues={leagues}
        selectedLeague={selectedLeague}
        setSelectedLeague={setSelectedLeague}
        clubs={clubs}
        selectedClub={selectedClub}
        setSelectedClub={setSelectedClub}
        minRating={minRating}
        setMinRating={setMinRating}
        maxRating={maxRating}
        setMaxRating={setMaxRating}
        tagOptions={tagOptions}
        bewertungskategorien={kategorien}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {paginatedScenes.length > 0 ? (
          paginatedScenes.map((scene) => (
            <SzeneKarte key={scene.id} scene={scene} from="/alle-szenen" onClick={() => handleNavigate(scene)} />
          ))
        ) : (
          <p>Keine Szenen verfügbar.</p>
        )}
      </div>

      <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={setCurrentPage} />
    </div>
  );
};

export default AlleSzenen;
